.panel-toggle {
  h2,
  h3 {
    margin: 30px 0;
  }

  .panels-wrapper {
    @include respond-to(medium) {
      padding: 0 0 40px 0;
    }
  }

  .nav-tabs {
    display: none;
    @include respond-to(medium) {
      display: block;
      border-bottom: 1px solid $light-grey;
      ul {
        @include resetList;
        display: flex;

        li {
          flex: 1 1 auto;
          margin-right: -4px;
          display: inline-block;
          vertical-align: bottom;
          text-transform: uppercase;
          text-align: center;
        }
      }
      h3 {
        height: 100%;
        font-size: 16px;
        margin: 0;
      }
    }
    a {
      height: 100%;
      padding: 20px 0;
      width: 100%;
      display: inline-block;
      vertical-align: bottom;
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px transparent;
      position: relative;
      overflow: hidden;
      color: $orange;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 50%;
        right: 50%;
        bottom: 0;
        background: currentColor;
        height: 4px;
        transition-property: left, right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }
      @include respond-to(large) {
        padding: 20px 0;
      }
      @include on-event {
        &:before {
          left: 0;
          right: 0;
        }
      }
    }
    li.active {
      a {
        font-weight: 600;
        &:before {
          left: 0;
          right: 0;
        }
      }
    }
  }
  .panel {
    box-shadow: 0 0 0 transparent;
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
    &.active {
      .panel-title .icon {
        transform: translateY(-50%) rotate(180deg);
        transition: all 0.3s ease-out;
      }
      .panel-content {
        display: block;
      }
    }
  }
  .panel-title {
    position: relative;
    padding: 20px 35px 20px 0;
    font-size: 20px;
    color: $orange;
    text-transform: uppercase;
    border-top: 3px solid $lighter-grey;

    @include respond-to(medium) {
      display: none;
      padding: 0 20px;
      border-top: none;

      a {
        @include on-event {
          text-decoration: none;
        }
      }
      .icon {
        display: none;
      }
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      fill: currentColor;
      transition: all 0.3s ease-out;
      margin: 0;
    }
  }
  .panel-content {
    display: none; // Visibility toggled in JS
    padding-bottom: 20px;

    @include respond-to(medium) {
      padding-bottom: 0;
    }

    .col {
      flex-grow: 1;
      @include respond-to(medium) {
        padding-right: 60px;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

// Variations
.bg-light-grey,
.bg-lighter-grey,
.bg-light-blue,
.bg-lighter-blue {
  .panel-toggle .panels-wrapper .panel {
    border-top: 3px solid #fff;

    @include respond-to(medium) {
      border: none;
    }
  }
}

// Fix cards inside grid-4+4+4 and 4Columns widgets to break the layout in revision history
.sf_colsOut.sf_3cols_1_33,
.sf_colsOut.sf_3cols_2_34,
.sf_colsOut.sf_3cols_3_33 {
  min-width: 1000px;
}
