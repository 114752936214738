.back-to-top {
  display: none; // Hiden on mobile
  position: fixed;
  z-index: 500;
  top: calc(50% + 35px);
  right: 15px;
  padding: 13px 13px 7px;
  opacity: 0;
  transition: opacity .3s;
  border-radius: 10px 0 10px 0;
  box-shadow: 0px 0px 10.32px 1.68px rgba(0, 29, 71, 0.1);
  background-color: $brand-color;

  @include on-event {
    background-color: lighten($brand-color, 10%);
  }

  @include respond-to(small) {
    display: block;
  }

  &.visible {
    opacity: 1;
  }

  .icon {
    transform: rotate(180deg);
    fill: #fff;
  }
}