.popup-large,
.popup-small {
  position: fixed;

  .popup-wrapper {
    transform-origin: 0 0;
    transition: transform 0.5s opacity 0.5s;
  }
  .popup-content,
  .popup-title {
    padding: 0 25px; // Prevent overlap with close button

    @include respond-to(medium) {
      padding: 0;
    }
  }
  .popup-content {
    overflow-y: auto;
  }
  .popup-close {
    position: absolute;
    top: 30px;
    right: 25px;
    .icon-close {
      fill: $brand-color;
    }
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  form {
    max-width: none;
    margin: 0;
  }

  input[type="radio"],
  input[type="checkbox"] {
    + label {
      display: block;
    }
  }

  legend {
    color: $text-color;
  }

  .cta-wrapper {
    margin-bottom: 0;
  }

  button,
  a {
    + button,
    + a {
      margin: 10px 0 0 10px;
    }
  }
}

.popup-small {
  z-index: 1000;
  bottom: 0;
  right: 15px;
  transform: translateY(-50%);
  padding: 30px 30px 40px 25px;
  width: 500px;
  max-width: calc(100% - 30px);
  background-color: #fff;
  border: 1px solid $light-blue;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  animation: slide-in 0.5s ease-in-out forwards;

  @keyframes slide-in {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @include respond-to(medium) {
    transform: none;
  }
  .popup-title,
  .popup-content {
    padding: 0 25px;
  }
  .popup-content {
    max-height: calc(100vh - 320px);

    @include respond-to(medium) {
      max-height: 250px;
    }
  }
}

.popup-large {
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(0, 0%, 0%, 0.5);

  .popup-close {
    top: 20px;
    right: 20px;
  }
  .popup-wrapper {
    @include border-color(bottom, $brand-color);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
    padding: 20px;
    background-color: #fff;
    animation: pop-in 0.5s ease-in-out forwards;
    .popup-content {
      max-height: calc(100vh - 220px);
    }
    @keyframes pop-in {
      0% {
        transform: scale(0) translate(-50%, -50%);
      }

      50% {
        transform: scale(1.1) translate(-50%, -50%);
      }

      100% {
        transform: scale(1) translate(-50%, -50%);
      }
    }

    @include respond-to(medium) {
      width: 75%;
      padding: 50px 100px 50px;
      .js-close {
        top: 50px;
        right: 50px;
      }
    }
  }
}
