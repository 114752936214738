.news-detail {
  padding: 60px 0 40px;

  .news-media {
    margin: 50px 0 40px;
    width: 100%;
  }

  .news-body {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-grey;
  }

  .news-content {
    clear: both;
  }

  h3 {
    margin: 10px 0 40px;
    color: $text-color;
    font-weight: bold;
  }

  .news-tags {
    margin-top: 30px;

    .news-tag {
      display: inline-block;
      margin: 0 0 10px 10px;
      padding: 10px 15px;
      background-color: $light-bg;
    }
  }

  .pagination {
    display: block;
    margin: 30px auto;
    text-align: center;
  }

  .news-date {
    color: $brand-color;
    font-size: 12px;
  }
}