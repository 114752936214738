.job-sidebar {
	.description {
		margin: 30px 0 15px;
		ul {
			@include resetList();
		}
	}
  	.cta-rounded,
  	.cta-rounded-blue {
		font-size: 24px;
		font-weight: 300;
		padding: 15px 28px;
		margin-top: 20px;
		width: 100%;
	}
	.job-summary >  .cta-rounded {
		width: auto;
	}
}