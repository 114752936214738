// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$image-path: "../Assets/images";
$icon-path: "../images/icons";


$brand-color: #003684;
//set themes colours
$themes: (
  individual: #009fe2,
  freelance: #001d47,
  company: #001d47,
  construction: #065673
);

$text-color: #252525;
$link-color: #009fe2;

$orange: #ee7f07;
$blue: #4b6b99;
$royal-blue: #2357a2;
$light-blue: #7f9ac1;
$lighter-blue: #c9e9f8;
$blue-border: #c3d0e2;
$dark-blue : #1b3981;
$light-bg: #eef4fb;
$light-grey: #cccccc;
$lighter-grey: #f4f4f4;
$grey: #9d9ea1;
$blue-filter : #ddeaf7;
$green: #049c6c;
$dark-grey: #313438;
$white: #fff;

// Other colors in the color palette (in principle these should not be variables as they are only used once)
// Background hoe kunnen we je helpen  - #ebeef2
// Breadcrumb  -  #ddeaf7

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'mobile': 641px,
  'small': 768px,
  'medium': 992px,
  'large': 1200px,
  'xlarge': 1440px
)
