.product-detail {
  padding-bottom: 0;

  .product-header {
    padding-bottom: 40px;
    @include respond-to(large) {
      min-height: 250px;
      width: 70%;
    }

    h1 {
      margin-bottom: 20px;
    }
  }

  .product-advantages {
    padding: 40px 0;
    color: #fff;
    background-color: $brand-color;
    @include border-color(bottom);

    &::after {
      bottom: -4px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }
    h3 {
      margin-bottom: 40px;
    }

    .container {
      position: relative;
    }

    ul {
      @extend .checked;
      margin-bottom: 0;
    }

    .product-media {
      display: none;
      position: absolute;
      top: -300px;
      right: 0;
      max-width: 350px;
      box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.5);

      @include respond-to(large) {
        display: block;
      }
    }

    .cta,
    .cta-rounded {
      margin-top: 20px;
      padding: 20px 20px !important;
    }
  }

  body.individual & {
    .product-advantages {
      background-color: $brand-color;
    }
  }
}

.product-detail-procons {
  .product-advantages {
    position: relative;
    &::before {
      content: "";
      background-color: $brand-color;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: calc(100% - 60px);
      width: 100%;
    }
  }
  h1 .highlight {
    font-weight: 700;
  }
  .row {
    @include respond-to(medium) {
      display: flex;
    }
  }
  img {
    display: none;
    position: absolute;
    bottom: 50px;
    right: 15px;
    box-shadow: -27px -3px 60px -10px rgba(0, 0, 0, 0.21);
    @include respond-to(large) {
      display: block;
    }
  }
  .card {
    height: 100%;
    @include respond-to(medium) {
      padding: 38px 35px;
    }
    h3 {
      color: $brand-color;
      margin-bottom: 20px;
      margin-top: 0;
      @include respond-to(large) {
        font-size: 33px;
        letter-spacing: 1.4px;
        margin-bottom: 45px;
      }
    }
    ul {
      padding-left: 0;
    }
    li {
      padding-left: 40px;
      position: relative;
      min-height: 30px;
      letter-spacing: 0.7px;
      @include respond-to(medium) {
        margin-bottom: 19px;
        padding-left: 46px;
      }
    }
    .icon {
      position: absolute;
      left: -4px;
      top: -6px;
      height: 35px;
      width: 35px;
    }
  }
  li {
    &::before {
      // display: none;
      width: 35px;
      height: 35px;
      background-color: transparent;
      background-size: 100%;
      position: absolute;
      top: -6px;
      left: -4px;
    }
  }
  .product-header {
    margin-bottom: 70px;
  }
  .pros-list {
    li::before {
      background-image: url(/Assets/images/icons/check.svg);
      fill: orange;
    }
  }
  .cons-list {
    // .icon {
    //   fill: $link-color;
    // }
    li::before {
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20data-name%3D%22Layer%201%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2248%22%20height%3D%2248%22%3E%3Cpath%20fill%3D%22%23009fe2%22%20d%3D%22M24%204a20%2020%200%201%200%2020%2020A20%2020%200%200%200%2024%204zm2%2030h-4v-4h4zm0-8h-4V14h4z%22/%3E%3C/svg%3E");
    }
  }
  .button-container {
    text-align: center;
    margin: 50px 0;
    a.cta-rounded {
      padding: 18px 25px;
    }
  }
}
