.js-emkSelect{
  position: absolute;
  top:  0px;
  left: 0px;
  /*	z-index: 10;*/
  margin: 0px;
  /*	height: inherit;*/
  width: 100%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -khtml-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select_wrapper {
  display: inline-block;
  vertical-align: middle;
  /* Older IE : (reads the proprieties after the *, ignored by modern browsers, weird unrecommanded hack...) */
  *vertical-align:auto;
  *zoom:1;
  *display:inline
}
.select_substitute.disabled{
  color: #999;
  background-color: #ededed;
}
.select_substitute.error{
  color: #f00;
  background-color: #fbeeef;
  border-color: #f00;
}
/* Default class applied on the substitute with positionning rules */
.select_substitute {
  display: inline-block;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
/* custom/option class with styling rules */
.select_substitute_style{
  background-color: #FFF;
  /* Place an arrow as background-image */
  background-image: url(../img/select_arrow.png);
  background-position: 90% center;
  background-repeat: no-repeat;
  /* Border */
  border-width: 1px;
  border-style: solid;
  border-color: #c7d4dd;
  border-radius: 2px;
  /* Box shadow */
  -webkit-box-shadow: inset 1px 1px 3px #d8d9da;
  box-shadow: inset 1px 1px 3px #d8d9da;
  /* Box size */
  line-height: 34px;
  padding: 0 10px;
  height: 36px;
  /* Text */
  font-size: 11px;
  color: #454b54;
  text-align: left;
}

/* Default class applied on the dropdown list with positionning rules */
.options_list{
  display: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  width: 100%;
  top: 35px; /* match the height of the .select_substitute minus the thickness of the bottom border */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 5;
}
/* custom/option class with styling rules */
.options_list_style{
  background-color: white;
  border: 1px solid #c7d4dd;
}

.options_list_style .groupItem {
  padding: 10px 0;
  font-size: 12px;
}
.options_list_style .groupItem span {
  font-weight: bold;
  padding: 0 5px;
}
.options_list_style ul {
  list-style-type: none;
  padding: 0;
}
.options_list_style .optionItem{
  margin: 0;
  padding: 10px 5px;
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
}
.options_list_style .groupItem .optionItem{
  padding-left: 8px;
}
.options_list_style .optionItem:hover{
  background-color: #d8d9da;
}
.options_list_style .optionItem.selected{
  background-color: #D5EAF9;
}

/* ALTERNATIVE THEME (you don't need this if you only have 1 type of select on your page)*/

.select_substitute_alt {
  background-color: #5E697B;
  background-image: url(../img/select_arrow.png);
  background-position: 90% center;
  background-repeat: no-repeat;
  border-width: 2px;
  border-style: solid;
  border-color: #333;
  -webkit-box-shadow: inset 1px 1px 3px #333;
  box-shadow: inset 1px 1px 3px #333;
  line-height: 30px;
  padding: 0 10px;
  height: 32px;
  font-size: 11px;
  color: #ffE;
  text-align: left;
}
.options_list_alt{
  top: 30px;
  background-color: #5E697B;
  border-color: #02497B;
  color: white;
  border-top: none;
}

.options_list_alt .groupItem {
  padding: 4px 0 12px 0;
  font-size: 13px;
}
.options_list_alt .groupItem span {
  padding: 0 8px;
  line-height: 200%;
}
.options_list_alt ul {
  list-style-type: none;
  padding: 0;
}
.options_list_alt .optionItem{
  margin: 0;
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  font-style: italic;
}
.options_list_alt .groupItem .optionItem{
  padding-left: 15px;
}
.options_list_alt .optionItem:hover{
  background-color: #E4771B;
}
.options_list_alt .optionItem.selected{
  background-color: #02497B;
}
