// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

/// Managing Responsive Breakpoints
/// https://www.sitepoint.com/managing-responsive-breakpoints-sass/
// See Breakpoints in _variables.scss
@mixin respond-to($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
    // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

// Set a different theme-color depending of the body class
// accept one or several parameter(s)
@mixin autoThemeColor($properties, $bodyClass: false) {
    @each $theme, $color in $themes {
        @each $property in $properties {
            @if $bodyClass {
                body.#{$theme}.#{$bodyClass} &{
                    #{$property}: $color;
                }
            }
            @else {
                body.#{$theme} &{
                    #{$property}: $color;
                }
            }      
        }
    }
}

// Add a border-color to a block:
// @prop {direction} define the position of the border
// @prop {color} by default, it will be in theme-color but can be overrided when a new one it's define
@mixin border-color($direction, $color:false) {
    @if not index(top right bottom left, $direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }

    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        // adapt the color
        @if $color {
            background-color: $color;
        }
        @else {
            $parameters: background-color;
            @include autoThemeColor($parameters);
        }

        // adapt the position
        @if $direction == top {
            width: 100%;
            height: 4px;
            top: -5px;
            left: 0;
        } 
        @else if $direction == right {
            height: 100%;
            width: 4px;
            top: 0;
            right: -5px;
        }
        @if $direction == bottom {
            width: 100%;
            height: 4px;
            bottom: -5px;
            left: 0;
        } 
        @else if $direction == left {
            height: 100%;
            width: 4px;
            top: 0;
            left: -5px;
        }
    }
}

@mixin resetList() {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    li{
        margin: 0;
        padding: 0;

        &::before {
            content: none; // Reset the default list-style applied in base.scss
        }
    }
}
// add this mixin inside a before or after
@mixin iconCss($iconName, $width, $height) {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: $width;
    height: $height;
    background: url('#{$icon-path}/#{$iconName}.svg') center center no-repeat;
    background-size: contain;
    margin-right: 7px;
}
