.breadcrumbs {
  display: none;
  position: relative;
  background-color: #ddeaf7;
  padding: 10px 0;
  align-items: center;

  @include respond-to(small) {
    display: flex;
  }

  ul {
    @include resetList()
  }

  li {
    display: inline-block;
    font-size: 14px;
    color: $brand-color;
  }

  a {
    $parameters: color;
    @include autoThemeColor($parameters);
  }

  .icon-home {
    position: relative;
    top: 2px;
    height: 15px;
    $parameters: fill;
    @include autoThemeColor($parameters);
  }

  .icon-caret {
    position: relative;
    top: -2px;
    height: 6px;
    transform: rotate(-90deg);
    fill: $brand-color;
  }
}