// This module uses styles defined in components/_panel-toggle.scss
.product-teaser {
  .product-card-wrapper {
    margin: 0 -15px 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .product-card {
    position: relative;
    margin: 15px;
    padding: 20px;
    border: 1px solid $brand-color;
    @include respond-to(small) {
      margin: 15px;
      width: calc(50% - 30px);
    }

    @include respond-to(medium) {
      width: calc(33.33% - 30px);
      margin: 25px 15px 50px;
      padding: 20px 30px 50px;
    }

    .product-title {
      margin: 0 0 20px;
      font-size: 22px;
      font-weight: bold;
    }

    .link {
      margin-top: 20px;

      @include respond-to(medium) { // On higher resolution ".link" becomes a ".cta"
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translate(-50%);
        display: inline-block;
        background-color: $link-color;
        padding: 12px 15px;
        color: white;
        font-weight: 600;
        transition: background-color .3s ease-out;
        @include on-event {
          text-decoration: none;
          background-color: lighten($link-color, 10%);
        }
        .icon {
          transform: rotate(-90deg);
          fill: white;
        }
      }

      .icon {
        @include respond-to(medium) {
          display: none;
        }
      }
    }
  }
}