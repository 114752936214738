.searchbar {
  position: relative;
  display: inline-block;
  min-width: 100%;
  margin: 20px 0;

  form {
    min-width: 100%;
    margin: 0;
  }

  input[type="text"] {
    width: 100%;
    padding: 15px 30px 15px 35px;
    border: 1px solid $brand-color;
  }

  input[type="submit"], .icon-submit {
    position: absolute;
    top: 27px;
    left: 5px;
    transform: translateY(-50%);
    width: 30px;
  }

  input[type="submit"] {
    background-color: transparent;
    border: none;
  }

  .search-clear {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .icon-search-clear {
    right: 8px;
    width: 20px;
    height: 20px;
    padding: 5px;
    fill: $brand-color;
    border-radius: 50%;
    background-color: $light-bg;
  }
}