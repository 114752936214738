.tip {
	.tip-wrapper {
		background-color: $light-blue;
		color: white;
		padding: 20px;
		@include respond-to(small) {
			padding: 20px 80px;
			position: relative;
		}
		.icon {
			display: inline-block;
			vertical-align: middle;
			fill: white;
			width: 30px;
			height: 30px;
			margin-right: 5px;
			@include respond-to(small) {
				width: 40px;
				height: 40px;
				position: absolute;
				top: 18px;
				left: 27px;
			}
		}
		h4 {
			color: white;
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase;
			display: inline-block;
			vertical-align: middle;
		}
		p:first-of-type {
			margin-top: 15px;
		}
	}
}