.related-news {
  @include respond-to(medium) {
    margin-top: 65px;
  }
  
  .news-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    border-bottom: 1px solid $lighter-grey;

    @include on-event() {
      text-decoration: none;
    }

    h3 {
      font-size: 20px;
      color: $text-color;
      font-weight: bold;
    }  
  }
  
  .news-media {
    width: 75px;
    margin-right: 10px;
  }

  .news-date {
    font-size: 12px;
    color: $text-color;
  }

  .news-content {
    h3 {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}