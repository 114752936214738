.page-preview {
  .teaser-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 0px -20px;
  }

  .teaser {
    width: 100%;
    position: relative;
    background-color: $light-bg;
    padding: 30px;
    border-radius: 0 0 20px 0;
    margin: 20px 10px 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include respond-to(small) {
      width: calc(50% - 40px);
    }

    @include respond-to(medium) {
      width: calc(33.3333% - 40px);
    }
  }

  .teaser-content {
    flex-grow: 1;
    max-width: 100%;
  }

  h3 {
    font-size: 25px;
    font-weight: 400;
    color: $text-color;
  }

  .img-wrapper {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid white;
    margin: -40px 0 0 -40px;
    @include border-color(bottom);

    a {
      display: block;
    }

    img {
      width: 100%;
      // height: 175px;
      object-fit: cover;
      transition: transform 0.3s cubic-bezier(0.25, 0.25, 0.75, 0.75);
      will-change: transform;
    }
  }
}

.blog-preview {
  .teaser {
    background-color: transparent;
    margin: 0;
    display: block;
  }

  .img-wrapper {
    margin: 0;
    overflow: hidden;

    &:after {
      bottom: 0;
    }

    a:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}