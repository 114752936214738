.faq-list, .faq-block {
  .intro {
    margin-bottom: 50px;
  }

  .faq-item {
    padding: 20px 0;
  }

  .faq-item-title {
    display: flex;
    margin-top: 10px;
    color: $link-color;
    cursor: pointer;
    font-weight: bold;

    .icon {
      flex-shrink: 0;
      width: 20px;
      fill: $link-color;
      transition: transform .3s ease-out;
    }

    .faq-item-title-text {
      margin-left: 10px;
    }

    &.open {
      .icon {
        transform: rotate(180deg);
      }

      + .faq-content {
        display: block;
      }
    }
  }

  .faq-content {
    display: none;
  }
}

.faq-list {
  .faq-item-title {
    font-size: 28px;
  }

  .faq-item {
    padding: 20px 0;
    border-top: 1px solid $lighter-grey;
  }

  .product-tag {
    margin-left: 10px;
    display: inline-block;
    font-size: 16px;
    color: $brand-color;
  }
}

.faq-block {
  .faq-item-title {
    font-size: 20px;
    margin-top: 5px;
  }

  .faq-item {
    padding: 20px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $light-blue;
    }
  }


  .link {
    display: inline-block;
  }

  .all-faq {
    margin-top: 20px;
    font-size: 18px;
  }
}