// .download-item is used both within the downloads widget and as a standalone block
.download-item {
  position: relative;
  display: inline-block;
  padding: 20px 20px 30px 0;
  @include respond-to(small) {
    padding: 10px 20px 20px 0;
  }
  
  .item-media {
    float: left;
    margin: 0 20px 30px 0;
    height: 150px;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.15);
    @include respond-to(small) {
      margin-bottom: 0;
    }
  }
  .item-date {
    color: $brand-color;
    font-size: 12px;
  }

  h3 {
    margin-top: 10px;
    font-size: 20px;
    color: $text-color;
    font-weight: 400;
  }

  a {
    font-size: 16px;
  }
}
// VARIATIONS for downloads-list
.downloads {

  .download-item {
    width: 100%;
    padding: 30px 10px;
    border-bottom: 1px solid $lighter-grey;
    @include respond-to(medium) {
      width: calc(50% - 84px);
      margin: 0 40px;
    }
  }
}