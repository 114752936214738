/*
 * Autocomplete used for the search
 * Solution provided by Sitefinity
 */
.ui-autocomplete {
	@include resetList();
	max-width: 100%;
	max-height: 200px;
	overflow-y: auto;
	padding: 10px;
	background-color: #fff;
	border: 1px solid $light-grey;

	li {
		padding: 5px;
	}

	a {
		cursor: pointer;
	}
}

/*
 * Autocomplete used for the Map
 * based on twiter typeahead.js
 * src: https://github.com/twitter/typeahead.js
 */
.map-search {

	.twitter-typeahead {
		display: block !important; // override inline style
	}

	.tt-menu {
		width: 100%;
		background-color: white;
		text-align: left;
    	padding: 3px 0;
		border-top: 1px solid $brand-color;
		box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.5);
		max-height: 200px;
		overflow-y: scroll;
	}

	.tt-hint {
		color: $grey;
		text-transform: capitalize;
	}

	.tt-suggestion {
		padding: 8px 15px;
		text-transform: capitalize;
		@include on-event {
			background-color: $lighter-grey;
			cursor: pointer;
		}
	}
}
