.simulation-cta {
  position: fixed;
  z-index: 10;
  background: $link-color;
  padding: 15px 15px 15px;
  box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 20px 0 20px 0;

  @include respond-to(medium) {
    flex-direction: row;
    width: auto;
  }

  span {
    margin-bottom: 10px;
    font-size: 14px;
    color: $white;

    @include respond-to(medium) {
      font-size: 16px;
      margin-bottom: 0;
      margin-right: 15px;
    }
  }

  a {
    font-weight: 700;
  }

  .cta-rounded {
    flex-shrink: 0;
  }

  .disabled {
    pointer-events: none;
    background-color: $white;
  }
}
