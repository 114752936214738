.search-box {
    display: none; // Hidden by default on mobile (inside the menu)
    position: absolute;
    top: 80px;
    width: 100%;
    padding: 10px;
    z-index: 100;

    @include respond-to(small) {
        display: block;
        right: 10px;
        top: 60px;
        width: auto;
        padding: 0;
    }

    &.open { // Toggled with javascript
        display: block;

        .search-input {
            display: block;
            border: 1px solid $brand-color;
        }

        .icon-search-open {
            display: none;
        }

        .icon-search-close {
            display: block;
        }

        input[type="submit"], .icon-submit {
            display: block;
        }
    }

    form {
        position: relative;
        margin: 0;
    }

    .search-label {
        display: none;
    }

    .search-input {
        padding: 10px 30px 10px 10px;
        border: 1px solid $brand-color;
        width: 100%;

        @include respond-to(small) {
            display: none; // Hidden by default on desktop
            padding: 15px 30px 15px 35px;
            border: none;
            width: 360px;
        }
    }

    input[type="submit"], .icon-submit {
        display: none;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;

        @include respond-to(small) {
            right: auto;
            left: 5px;
        }
    }

    input[type="submit"] {
        z-index: 100; // Over the icon
        margin-top: 0;
        background-color: transparent;
        border: none;
    }

    .icon-submit {
        fill: $brand-color;
    }

    .search-toggle {
        display: none; // Always hidden on mobile

        @include respond-to(small) {
            display: block;
        }
    }

    .icon-search-open, .icon-search-close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        fill: $link-color;
        cursor: pointer;

        @include respond-to(small) {
            right: 0;
            width: 25px;
            height: 25px;
            fill: $brand-color;
        }
    }

    .icon-search-close {
        display: none; // Visibility toggled in JS
        right: 8px;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 50%;
        background-color: $light-bg;
    }

}

.home {
    .utilities {
        display: flex;

        .search-box {
            display: block;
            position: relative;
            top: 0;
            flex: 1;
            padding: 20px 15px;
            @include respond-to(small) {
                position: absolute;
                top: calc(100% + 50px);
                right: 0;
                flex-direction: column;
                align-items: flex-end;
                padding: 20px;
            }
        }

        .search-label {
            @include respond-to(small) {
                display: block;
                color: white;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 10px;
            }
        }

        .search-toggle {
            display: none;
        }

        .search-input {
            display: block;
            position: relative;
            max-width: none;
            width: 100%;
            margin: 0;
            padding: 15px 55px 15px 15px;
            font-size: 20px;
            border: none;
            @include respond-to(small) {
                padding-right: 70px;
            }
        }

        input[type="submit"], .icon-submit {
            display: block; // Always displayed on home page
            position: absolute;
            left: auto;
            right: 10px;
            height: 50px;
            width: 50px;
        }

        .icon-submit {
            z-index: 10;
        }
    }
}

// Apply color depending on the body.home.$theme
.search-box {
    $parameters: background-color;
    @include autoThemeColor($parameters, "home");

    .icon-submit {
        $parameters: fill;
        @include autoThemeColor($parameters, "home");
    }
}