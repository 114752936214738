.pagination {
  margin: 30px -20px;
  display: block;
  text-align: center;
  @include respond-to(small) {
    margin: 30px auto;
  }

  .num {
    cursor: pointer;
  }

  .num {
    color: $brand-color;
    font-size: 19px;
    padding: 10px 0px;
    margin: 0 10px;
    border-bottom: 2px solid transparent;

    @include respond-to(small) {
      margin: 0 12px;
      padding: 10px 5px;

    }

    @include on-event {
      text-decoration: none;
      border-bottom: 2px solid $link-color;
    }
    &.active {
      text-decoration: none;
      font-weight: bold;
      border-bottom: 2px solid $link-color;
    }
    &.num-first-dots,
    &.num-last-dots {
      margin: 0;
      @include respond-to(small) {
        margin: 0 12px;
      }
      @include on-event {
        cursor: default;
        border-bottom-color: transparent;;
      }
    }

  }

  &.visible{
    display: block;
  }
}