.news-individual {
  position: relative;
  padding: 0;

  .news-individual-title {
    position: absolute;
    z-index: 100;
    top: 30px;
    width: 100%;
  }

  h2 {
    text-align: center;
    color: #fff;
  }

  .slick-track {
    display: flex;
  }

  .slide {
    position: relative;
    height: auto;
    background-size: cover;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: hsla(0, 0%, 0%, 0.5);
    }
  }

  .slide-wrapper,
  .slide-wrapper .container {
    height: 100%;
  }

  .slide-body {
    height: calc(
      100% - 340px
    ); // Stretch all slides to cover the whole section even when there is less content

    @include respond-to(small) {
      height: calc(100% - 210px);
    }
  }

  .slick-prev,
  .slick-next {
    top: auto;
    bottom: 20px;
    z-index: 20;
    transform: none;
    height: 100px;
    width: 30px;
    background-image: url("../images/icons/caret-large.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    @include respond-to(small) {
      top: 50%;
      bottom: auto;
      transform: translate(0, -50%);
    }

    &::before {
      content: none;
    }

    &.slick-disabled {
      opacity: 0.2;
    }
  }

  .slick-prev {
    left: calc(50% - 100px);
    transform: rotate(180deg);

    @include respond-to(small) {
      left: 25px;
    }
  }

  .slick-next {
    right: calc(50% - 100px);

    @include respond-to(small) {
      right: 50px;
    }
  }

  .slide-wrapper {
    position: relative;
    z-index: 10;
  }

  .slide-body {
    margin: 200px 0 140px;
    padding: 30px 30px 80px;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;

    @include respond-to(small) {
      min-height: 480px;
      position: relative;
      margin: 140px 220px 70px 70px;
      padding: 50px 200px 100px 50px;
    }
  }

  .date {
    font-size: 18px;
    color: $brand-color;

    @include respond-to(small) {
      font-size: 12px;
    }
  }

  h3 {
    margin: 10px 0 20px;
    font-size: 28px;
    font-weight: 600;
    color: $text-color;
  }

  .slide-media {
    width: 100%;
    margin: -60px 0 40px;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);

    @include respond-to(small) {
      margin: 0;
      position: absolute;
      top: 30px;
      right: -150px;
      width: auto;
    }

    @include border-color(left);
  }

  .dots-wrapper {
    position: relative;
  }

  .slick-dots {
    @include resetList;
    left: 25px;
    bottom: 170px;
    width: auto;

    @include respond-to(small) {
      left: 115px;
      bottom: 100px;
    }

    li {
      height: 15px;
      width: 15px;
      margin: 0 3px;
      border-radius: 50%;
      background-color: $light-grey;

      button {
        &::before,
        &::after {
          content: none;
        }
      }

      &.slick-active {
        background-color: $link-color;
      }
    }
  }
}
