/* When we enable the ajax form submit functionality,
 * sitefinity generates different html (without the tag form)
 * we need to use the class .contact-form for this
 */
.contact-form,
form {

  h1, h2, h3 {
    margin: 0; // Prevent an issue with iframes height not being calculated correctly
  }

  @include respond-to(small) {
    margin: 0 100px;
  }

  @include respond-to(large) {
    margin: 0 auto;
    max-width: 750px;
  }

  fieldset {
    border: 0 none;
    padding: 0;
    margin: 20px 0;
    > legend:first-child {
      border-bottom: 1px solid #e5e5e5;
      font-size: 18px;
      font-weight: 600;
    }
  }

  label, legend, .captcha {
    margin-top: 20px;
    color: $light-blue;
    font-weight: normal;
    font-weight: 300;
  }

  label, legend {
    display: block;
  }

  legend {
    margin-bottom: 0;
    font-size: 16px;
    border: 0 none;
  }

  input, select, textarea {
    appearance: none;
    border-radius: 0;
    width: 100%;
    max-height: 50px;
    padding: 12px 15px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    border: 1px solid $light-blue;
    color: $text-color;
    @include respond-to(medium) {
      margin-bottom: 0;
    }
  }

  select {
    position: relative;
    background: transparent url('#{$icon-path}/caret-down-light-blue.svg') no-repeat right 15px center / 15px;
  }

  .input-small {
    display: block;
    input {
      max-width: 100px;
      margin: 2px 0;
    }
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute;
    left: -9999px;

    + label {
      position: relative;
      display: inline-block;
      margin-right: 40px;
      padding-left: 40px;
      color: $text-color;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid $light-blue;
        cursor: pointer;
      }
    }
  }

  input[type="radio"] {
    + label::before {
      border-radius: 50%;
    }

    &:checked + label {
      &::after {
        content: "";
        position: absolute;
        left: 6px;
        top: 6px;
        height: 13px;
        width: 13px;
        background-color: $link-color;
        border-radius: 50%;
      }
    }
  }

  input[type="checkbox"] {
    &:checked + label {
      &::after {
        content: "\2713";
        position: absolute;
        left: 6px;
        top: 0;
        font-size: 20px;
        color: $link-color;
      }
    }

    &.checkbox-button {
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
      border: 0;

      &:focus + label {
        box-shadow: 0 0 5px 2px $lighter-blue;
      }

      & + label {
        background: $lighter-blue;
        padding: 10px 15px;
        border: 1px solid $blue-border;
        border-radius: 5px;
        margin-right: 10px;
        font-weight: 400;
        cursor: pointer;

        &::before,
        &::after {
          display: none;
        }
      }

      &:checked + label {
        background: $royal-blue;
        border-color: $dark-blue;
        color: $white;
      }
    }
  }

  input[type="file"] {
    max-height: unset;
  }

  .form-tooltip {
    position: absolute;
    top: -85px;
    right: 15px;
    height: 20px;
    width: 20px;
    padding: 0 6px;
    font-size: 14px;
    background-color: $light-bg;
    border: 1px solid $light-blue;
    border-radius: 50%;
    color: $brand-color;
    cursor: pointer;

    @include respond-to(medium) {
      position: relative;
      top: auto;
      right: 10px;
      height: 40px;
      width: 40px;
      margin-top: 50px;
      padding: 2px 15px;
      font-size: 24px;
    }

    .tooltip-content {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 35px;
      z-index: 10;
      min-width: 250px;
      padding: 20px;
      font-size: 16px;
      border: 1px solid $light-blue;
      background-color: #fff;

      @include respond-to(medium) {
        right: 55px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        border-left: 10px solid $light-blue;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -9px;
        transform: translateY(-50%);
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    }

    &.open .tooltip-content {
      display: block;
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: $grey;
  }

  .promo-box {
    border: 1px solid $orange;
    padding: 0 10px;
    p {
      color: $orange;
    }
  }

  .cta-wrapper {
    margin: 40px auto;
    vertical-align: top;
    &.align-center {
      text-align: center;
    }
    &.align-right {
      text-align: right;
      .cta,
      .cta-reverse {
        display: inline-block;
        font-size: 16px;
      }
    }
  }

  iframe[title="recaptcha"], iframe[title="widget recaptcha"] {
    margin-top: 20px;
  }

  button[type="submit"], input[type="submit"] {
    margin-top: 20px;
  }
}

.contact-form {
  button[type="submit"], input[type="submit"] {
    display: block;
    margin: 40px auto;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: lighter;
    line-height: normal;
    @include respond-to(medium) {
      margin-bottom: 0;
    }
  }
}

// Prevents asp.net forms (magical stuff, not actual forms for users) to get styles and break their inner layouts
[id="aspnetForm"].sfPagePreviewWrp {
  max-width: inherit;
}
