.filter{
	padding: 0;
	margin-top: 25px;
	.filterType{
		padding: 0;
		overflow: hidden;
	}

	li{	
		&:before{
			display: none;
		}
		padding : 0;
	}

	label{
		margin: 0;
	}

	label:last-child{
		width: 100%;
	}

	input{
		display: none;
	}

	.filter-element{
		border: 1px $blue-border solid;
		margin-right: 20px;
		position: relative;
		cursor: pointer;
		float: left;
		background: #fff;
		width: 100%;
		@include respond-to(medium) {
			width: auto;
    	}
		

		&:last-child{
			margin-right: 0;
		}

		.icon{
			fill: $dark-blue;
			position: absolute;
			right: 13px;
			top: 15px;
			transition: transform .3s ease-out;
			width: 15px;
			height: 15px;
		}


		ul{
			display: none;
			margin-top: 20px;
		}

		li{
			display: flex;
			align-items: center;
			
		}

		.options{
			padding: 0 20px;
		}
		
		.filter-button{
			padding: 10px 90px 10px 10px;
		}	

		&.open {
			border: 1px $dark-blue solid;
			box-shadow: 9px 0px 19px -2px rgba(199,199,199,0.32);

			.icon-caret {
				transform : rotate(180deg);
			}	
		}

		&.still-open{
			width: 100%;
			height: auto;
			&.still-toggle{
				height: 230px;
				padding-bottom: 30px;
				overflow: hidden;
				.toggle-button{
					display: block;
				}
			}
			.icon-caret {
				display: none;
			}
			ul{
				margin-top: 10px;
				display: flex;
				flex-direction: column-reverse;
			}
			.toggle-button{
				display: none;
				width: 100%;
				background: #fff;
				text-align: center;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				outline: none;
				&:before{
					content: "";
					display: block;
					width: 100%;
					height: 50px;
					position: absolute;
					top: -50px;
					left: 0;
					right: 0;
					background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.75),rgba(255,255,255,1));
				}
				svg{
					fill: $link-color;
					width: 20px;
					height: 20px;
				}
			}
			&.still-open-show-all{
				height: auto;
				.toggle-button {
					svg{
						transform: rotate(180deg);
					}
					&:before {
						content: normal;
					}
				}
			}
		}

		.filter-title{
			margin: 0;
			font-size: 22px;
			font-weight: 400;
			color: $link-color;
			position: relative;
		}

		.option{
			cursor: pointer;
			label{
				cursor: pointer;
			}
		}

		.checkbox{
			position: relative;
			padding-left: 30px;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 20px;
				min-width: 20px;
				height: 20px;
				border: 1px $link-color solid;
				display: block;
				margin-right: 10px;
			}

			.icon {
				fill : #fff;
				position: absolute;
				top: 2px;
				left: 2px;
				opacity: 0;
  				transition: opacity .2s linear;
			}
		}

		input:checked + .checkbox{
			&::before {
				background:$link-color;
			}

			.icon {
				opacity: 1;
			}
		}
	}

	.selected-filter{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.your-selection {
			color: $light-blue;
		}

		li{
			display: inline-block;
			padding: 10px 50px 10px 10px;
			margin: 0 10px 0 0;
			margin-top: 15px;
			background: $blue-filter;
			position: relative;
			.cross{
				width: 13px;
				height: 13px;
				display: block;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translate(0,-50%);
				cursor: pointer;
				.icon{
					width: 100%;
					height: 100%;
					fill: $text-color;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}

.error-msg{
	font-size: 24px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	margin: 15px 0 35px 0;
}