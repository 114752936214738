.social-links {
	.news-item & {
		margin-bottom: 15px;
      	@include respond-to(small) {
      		float: right;
      		margin-bottom: 20px;
      	}
	}
	.news-detail & {
		position: absolute;
	    top: 0;
	    right: 0;
	    margin-bottom: 20px;
	}

	.addthis_inline_share_toolbox_w4oo {
		position: relative;
		top: 5px;
		display: inline-block;
	}

    a,
    .icon {
	    display: inline-block;
	    margin-right: 5px;
	    width: 30px;
	    height: 30px;
	    @include respond-to(medium) {
	      	margin-right: 0;
	      	width: 25px;
	    	height: 25px;
	    }
    }

    .print-link {
	    display: none;
	    @include respond-to(medium) {
	        display: inline-block;
	        margin-right: 20px;
	        position: relative;
	        &:after {
	          content: "";
	          display: block;
	          width: 1px;
	          height: calc(100% - 5px);
	          background-color: $grey;
	          position: absolute;
	          right: -12px;
	          top: 3px;
	        }
	    }
    }

    .icon-print {
	    fill: $grey;
	    border: 1px solid $grey;
    }

    .icon-mail {
      	fill: $blue;
    }

    .icon-twitter {
      	fill: #50ABF1;
    }

    .icon-linkedin {
      	fill: #017fb2;
    }
    
    .icon-facebook {
      	fill: #3c5a95;
    }
}
