.news-corporate {
  padding: 30px 0 80px;

  .news-cards-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .news-card {
    width: 100%;
    margin-top: 60px;
    padding: 30px;
    $parameters: background-color;
    @include autoThemeColor($parameters);
    color: #fff;

    @include respond-to(small) {
      position: relative;
      width: calc(50% - 25px);
      margin-top: 0;
      margin-bottom: 50px;
      padding: 30px;
    }
    @include respond-to(large) {
      padding-right: 200px;
    }
  }

  body.individual & {
    .news-card {
      background-color: $brand-color;
    }
  }

  h3 {
    margin: 10px 0 20px;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
  }

  .slide-media {
    width: 100%;
    margin: -60px 0 40px;

    @include respond-to(large) {
      margin: 0;
      position: absolute;
      top: 25px;
      right: -25px;
      width: 210px;
    }

    @include border-color(left, $brand-color);
  }

  .slider-image {
    width: 100%;
  }

  .cta {
    margin-top: 30px;
  }

  .all-news {
    float: right;
    margin-top: 30px;
  }
}