// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main-header {
  position: relative;
  background-color: #fff;

  ul {
    @include resetList();
  }

  .nav-subsegments,
  .nav-secondary {
    a {
      display: block;
      padding: 15px 20px;

      @include respond-to(small) {
        padding: 9px 15px;
      }
    }
  }

  .header-mobile {
    position: relative;
    height: 90px;

    @include border-color(bottom);

    @include respond-to(small) {
      display: none;
    }

    &.open {
      &::after {
        content: none;
      }
    }

    &::after {
      bottom: -4px;
    }

    .menu-toggle {
      width: 35px;
      height: 35px;
      position: absolute;
      top: calc(50% - 17px);
      right: 20px;
      margin-left: auto;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: $brand-color;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 12px;
        }

        &:nth-child(4) {
          top: 24px;
        }
      }

      &.open {
        span {
          &:nth-child(1) {
            top: 12px;
            width: 0%;
            left: 50%;
          }

          &:nth-child(2) {
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }

          &:nth-child(4) {
            top: 12px;
            width: 0%;
            left: 50%;
          }
        }
      }
    }
  }

  .menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;

    @include respond-to(small) {
      max-height: 1000px;
    }

    &.open {
      margin-top: 60px;
      max-height: 1000px; // We need to use a hardcoded max-height here for the animation

      @include respond-to(small) {
        margin-top: 0;
      }
    }

    .header-wrapper {
      @include respond-to(small) {
        display: flex;
        justify-content: space-between;
        background-color: $brand-color;
      }
    }

    .nav-segments {
      &::after {
        content: none;

        @include respond-to(small) {
          content: "";
          position: absolute;
          height: 90px;
          width: 100%;
          background-color: #fff;
        }
      }

      @include respond-to(small) {
        margin-bottom: 50px;
      }

      @include respond-to(large) {
        margin-bottom: 90px;
      }

      > ul {
        font-size: 0;
      }

      .segment-item {
        position: relative;
        border-bottom: 1px solid #fff;
        font-size: 15px;

        @include respond-to(small) {
          font-size: 13px;
          position: static;
          display: inline-block;
          border-bottom: none;
        }

        &.active {
          @include respond-to(small) {
            &.individual {
              .segment-item-label {
                background-color: #{map-get($themes, individual)};
              }
            }

            &.freelance,
            &.company {
              .segment-item-label {
                background-color: #{map-get($themes, company)};
              }
            }

            &.construction {
              .segment-item-label {
                background-color: #{map-get($themes, construction)};
              }
            }

            .nav-subsegments {
              display: block;
            }
          }

          .nav-subsegments {
            max-height: 500px;
          }

          .segment-item-icon {
            transform: rotate(180deg);
          }
        }

        &.individual {
          .segment-item-label {
            background-color: #{map-get($themes, individual)};

            @include respond-to(small) {
              background-color: transparent;
            }
          }

          .subsegment-item a {
            color: #{map-get($themes, individual)};
          }

          .subsegment-item-icon {
            fill: #{map-get($themes, individual)};
          }
        }

        &.freelance,
        &.company {
          .segment-item-label {
            background-color: #{map-get($themes, company)};

            @include respond-to(small) {
              background-color: transparent;
            }
          }

          .subsegment-item a {
            color: #{map-get($themes, company)};
          }

          .subsegment-item-icon {
            fill: #{map-get($themes, company)};
          }
        }

        &.construction {
          .segment-item-label {
            background-color: #{map-get($themes, construction)};

            @include respond-to(small) {
              background-color: transparent;
            }
          }

          .subsegment-item a {
            color: #{map-get($themes, construction)};
          }

          .subsegment-item-icon {
            fill: #{map-get($themes, construction)};
          }
        }

        .segment-item-label {
          display: block;
          text-transform: uppercase;

          @include respond-to(small) {
            position: relative;
            background-color: transparent;

            &::after {
              content: "";
              position: absolute;
              top: 10px;
              right: 0;
              height: 25px;
              width: 1px;
              background-color: #fff;
            }
          }

          .segment-item-link {
            display: inline-block;
            padding: 15px 20px;
            color: #fff;

            @include respond-to(small) {
              padding: 10px;
            }

            @include respond-to(large) {
              padding: 10px 15px;
            }
          }
        }

        .segment-item-icon {
          position: absolute;
          top: 15px;
          right: 20px;
          fill: #fff;
          transition: transform 0.25s ease-in-out;
          pointer-events: none;

          @include respond-to(small) {
            display: none;
          }
        }
      }
    }

    .nav-subsegments {
      max-height: 0; // Visibility toggled in JS
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;

      @include respond-to(small) {
        display: none; // Only the subsegments of the active segment should be shown
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 10;
        padding: 0 0 0 130px;
        background-color: #fff;
      }

      @include respond-to(large) {
        padding-right: 180px;
        padding-left: 180px;
      }

      a {
        @include respond-to(small) {
          padding: 18px 5px;
        }

        @include respond-to(large) {
          padding: 35px 15px;
        }
      }

      .subsegment-item {
        position: relative;

        @include respond-to(small) {
          display: inline-block;
          a {
            font-weight: 400;
          }
          @include on-event {
            a {
              text-decoration: none;
            }
            &::after {
              transition: all 0.3s ease-out;
              height: 4px;
            }
          }
          &.active {
            a {
              font-weight: 600;
            }
            &::after {
              transition: all 0.3s ease-out;
              height: 4px;
            }
          }
        }

        @include respond-to(large) {
          font-size: 15px;
          @include on-event {
            &::after {
              height: 6px;
            }
          }
          &.active {
            &::after {
              height: 6px;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          z-index: 10;
          right: 50%;
          bottom: 0px;
          height: 0px;
          width: calc(100% - 10px);
          transform: translateX(50%);
          background-color: $light-bg; // >> WHY??
          $parameters: background-color;
          @include autoThemeColor($parameters);

          @include respond-to(large) {
            width: calc(100% - 30px);
          }
        }
      }

      .subsegment-item-icon {
        position: absolute;
        right: 20px;
        transform: rotate(-90deg);

        @include respond-to(small) {
          display: none;
        }
      }
    }

    .nav-secondary {
      font-size: 0;

      @include respond-to(small) {
        display: none; // Hidden on tablet
        margin-right: 60px;
      }

      @include respond-to(large) {
        display: block;
      }

      a {
        color: #fff;
        background-color: $brand-color;
      }
    }

    .secondary-item {
      position: relative;
      font-size: 16px;
      font-weight: 300;

      @include respond-to(small) {
        display: inline-block;
        font-size: 14px;
      }

      &.active {
        font-weight: bold;
      }

      &:not(:last-of-type) {
        &::after {
          content: "";
          position: absolute;
          z-index: 10;
          right: 50%;
          height: 1px;
          width: calc(100% - 40px);
          transform: translateX(50%);
          background-color: $blue;

          @include respond-to(small) {
            content: none;
          }
        }
      }
    }
  }

  .logo {
    display: none; // Visibility toggled in JS
    position: absolute;
    z-index: 15;
    top: 10px;
    left: 10px;
    height: 70px;

    @include respond-to(small) {
      display: block;
      top: 48px;
      left: 10px;
      height: 35px;
    }

    @include respond-to(large) {
      top: 59px;
      height: 50px;
    }

    &.visible {
      display: block;
    }
  }

  .language-picker {
    display: none; // Visibility toggled in JS
    position: absolute;
    z-index: 200;
    top: 0;
    padding: 35px 15px 30px 15px;
    font-size: 18px;
    color: $blue;
    cursor: pointer;
    background-color: #fff;

    @include respond-to(small) {
      display: block;
      right: 0;
      padding: 9px 15px 9px 15px;
      font-size: 14px;
      color: #fff;
      border-left: 1px solid #fff;
      background-color: $brand-color;
    }

    &.visible {
      display: block;
    }

    &::after {
      content: "";
      position: absolute;
      top: 25px;
      right: 0;
      height: 40px;
      width: 1px;
      background-color: $blue;

      @include respond-to(small) {
        display: none;
      }
    }
  }

  .my-federale {
    display: none; // Visibility toggled in JS
    position: absolute;
    z-index: 15;
    top: 25px;
    left: 80px;
    color: $blue;

    @include respond-to(small) {
      display: flex;
      top: 10px;
      left: auto;
      right: 70px;
      font-size: 12px;
      color: #fff;
    }

    @include respond-to(large) {
      top: 65px;
      left: auto;
      right: 50px;
      font-size: 16px;
      color: $blue;
    }

    &.visible {
      display: flex;
    }

    @include on-event {
      text-decoration: none;

      .sign-in {
        text-decoration: underline;
      }
    }

    .icon-login {
      position: relative;
      top: -3px;
      height: 35px;
      width: 35px;
      fill: $blue;

      @include respond-to(small) {
        height: 22px;
        width: 22px;
        fill: #fff;
      }

      @include respond-to(large) {
        height: 28px;
        width: 28px;
        fill: $blue;
      }
    }

    .my-federale-label {
      position: relative;
      margin-left: 10px;
      display: inline-block;
    }

    .sign-in {
      display: none;

      @include respond-to(large) {
        display: block;
        text-align: right;
      }
    }
  }
}

// Variations
body.home {
  .header-mobile {
    &::after {
      content: none;
    }
  }
  .menu.open {
    margin-top: 0;
  }
  .my-federale {
    @include respond-to(large) {
      right: 20px;
    }
  }
  .nav-secondary {
    border-bottom: 1px solid #fff;

    @include respond-to(small) {
      border-bottom: none;
    }
  }
  @include respond-to(large) {
    .main-header .menu .nav-subsegments {
      padding-right: 150px;
    }
  }
}
