// Hide print area
#print-area {
  display: none;
}

@-moz-document url-prefix() {
  @media print {
    body {
      margin-top: 30px;
    }
    .main-header {
      .logo {
        position: relative !important;
        top: -30px !important;
        left: 0 !important;
        height: 70px;
      }
    }
  }
}

@media print {
  .main-header {
    .logo {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
    }
  }

  .main-header a::after,
  .breadcrumbs,
  .nav-segments,
  .nav-subsegments,
  .main-header .menu,
  .search-box,
  .social,
  .social-links,
  .help-box,
  .back-to-top,
  .prev,
  .next,
  .link-prev,
  .print-link,
  .pagination,
  .howHelp,
  .cookie-banner,
  footer {
    display: none !important;
  }

  .card,
  .news-segment,
  .news-tag {
    border: 1px solid #000;
  }

  .news-detail {
    padding-bottom: 0;
    .news-media {
      margin-top: 0 !important;
    }
    .news-body {
      border-bottom: 0 none !important;
    }
  }

  .job-detail {
    .card {
      margin-top: 0 !important;
    }

    .job-detail-content {
      margin: 0 !important;

      ul li {
        list-style-type: disc;
      }
    }

    .cta-wrapper {
      display: none;
    }
  }

  .job-sidebar,
  .related-news {
    display: none;
    margin-top: 10px !important;
    padding-top: 0 !important;

    .cta-rounded,
    .cta-rounded-blue {
      display: none;
    }
  }

  // Map Print styles

  #activmap-canvas {
    display: none;
  }

  .contact {
    .nav-tabs,
    .panel-title,
    .banner,
    .highlight,
    .activmap-location,
    .activmap-places,
    .place-detail-view,
    .activmap-ui-wrapper,
    .activmap-toggle,
    .pin-individual-light-blue,
    .icon-arrow {
      display: none !important;
    }

    .detail-description {
      display: block;
      margin-bottom: 35px;
    }

    .MapDirection {
      font-weight: bold;
      display: inline-block;
      margin-top: 10px;
    }

    .activmap-wrapper .address,
    .activmap-wrapper .email-link,
    .activmap-wrapper .numbers,
    .activmap-wrapper .opening-hours {
      padding-left: 0 !important;
      margin: 5px 0 20px 50px !important;
      position: relative !important;
    }

    .activmap-wrapper .email-link {
      display: block;
    }

    .icon {
      fill: $link-color !important;
      position: absolute !important;
      left: -40px !important;
    }

    a[href]:after {
      content: none !important;
    }
  }

  #print-area {
    // Show print area
    display: block;
    padding-left: 0 !important;

    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/

    .detail-title {
      color: $link-color !important;
    }

    .question,
    .employees,
    .container-buttons {
      display: none !important;
    }

    .detail-half > * {
      padding-left: 0 !important;
    }

    .numbers {
      a {
        display: block;
      }
    }
  }
}
