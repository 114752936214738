.banner {
	position: relative;
	color: white;
	display: flex;
	align-items: center;
	padding: 40px 0;
	@include respond-to(small) {
		padding: 60px 0;
		min-height: 370px;
	}
	@include respond-to(medium) {
		padding: 100px 0;
		min-height: 360px;
	}
	.bg-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.inner {
		position: relative;
	}

	h1 {
		color: white;
		margin: 0 0 30px;
		hyphens: none;
		.highlight {
			hyphens: none;
		}
	}

	.cta, .cta-rounded {
		padding: 15px 30px;
		font-size: 24px;
		font-weight: lighter;
	}

	&.banner-align-left {
		.inner {
			@include respond-to(small) {
				width: 55%;
			}
		}
		p {
			display: none;
		}
		.cta,
		.cta-rounded {
			@include respond-to(medium) {
				margin-bottom: 20px;
			}
		}
	}

	&.banner-align-center {
		text-align: center;
		min-height: 250px;
		h1 {
			margin-bottom: 0;
		}
		p, a { display: none;}
	}

	&.banner-block {
		padding-top: 135px;
		@include respond-to(small) {
			padding-top: 80px;
			padding-bottom: 60px;
		}

		.inner {
			background-color: $brand-color;
			padding: 20px;
			border-bottom: 1px solid white;
			position: relative;
			@include respond-to(small) {
				width: 56%;
			}
			@include respond-to(medium) {
				width: 48%;
				padding: 40px;
			}
			@include border-color(bottom, $link-color);
		}

		h1 {
			.highlight {
				font-size: 35px;
				@include respond-to(medium) {
					font-size: 35px;
				}
				@include respond-to(large) {
					font-size: 43px;
				}
			}
		}

		p {
			margin-bottom: 35px;
		}

		.bg-image {
			height: 185px;
			background-position: 70% center;
			@include respond-to(small) {
				height: 360px;
				background-position: center center;
			}
		}
	}


}
