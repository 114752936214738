.help-box {
    display: none;
    @include respond-to(small) {
        display: block;
        width: 50px;
        transition: width .3s ease-out;
        position: fixed;
        z-index: 50;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background-color: $link-color;
        border-radius: 10px 0 10px 0;
        box-shadow: 0px 0px 10.32px 1.68px rgba(0, 29, 71, 0.1);
        color: white;
        overflow: hidden;
        @include on-event {
            width: 160px;
            transition: width .3s ease-out;
            color: white;
            .help-label {
                transform: translateX(0);
            }
        }
        .icon-question {
            font-size: 40px;
            line-height: 50px;
            display: inline-block;
            vertical-align: top;        
            padding: 0 15px;
        }
        
        .help-label {
            display: inline-block;
            vertical-align: top;
            line-height: 50px;
            padding-right: 15px;
            white-space: nowrap;
            position: absolute;
            transform: translateX(100%);
        }
        
        .help-cta {
            display: none;
        }
    }
}

.home {
    .help-box {
        display: block;
        width: 90px;
        padding: 0 7px;
        background: $brand-color;
        color: #fff;
        text-align: center;        

        @include on-event {
            background-color: lighten($brand-color, 10%);
            color: white;
            text-decoration: none;
        }

        .icon-question {
          font-size: 50px;          
        }

        .help-label {
          margin-top: -10px;
          font-size: 14px;          
        }

        .help-cta {
            display: none;
        }

        @include respond-to(small) {
            width: 137px;
            height: 137px;
            position: absolute;
            top: calc(100% + 187px);
            right: 0;
            border-radius: 0;
            transform: none;
            
            .icon-question {
                font-size: 58px;
                line-height: normal;
            }

            .help-label {
                display: block;
                font-size: 22px;
                position: relative;
                right: 0;
                top: auto;
                transform: none;
                width: auto;
                line-height: normal;
                vertical-align: initial;
                padding: 0;
            }
            .help-cta {
                display: block;
                margin-top: 3px;
                font-weight: 400;
                color: $link-color;
                .icon {
                    height: 12px;
                    width: 12px;
                    margin-left: 10px;
                    transform: rotate(-90deg);
                    fill: $link-color;
                }
            }
            @include on-event {
                background-color: lighten($brand-color, 10%);
                color: white;
                width: 137px;
                .contact-label {
                    transform: none;
                }
            }
        }
        @include respond-to(medium) {
            width: 170px;
            height: 170px;
            .icon-question {
                font-size: 78px;
            }
            @include on-event {
                width: 170px;
            }
        }
    }
}