.info {
	padding-top: 0;
    color: white;
	$parameters: background-color;
    @include autoThemeColor($parameters);

    body.individual & {
    	background-color: $brand-color;
    }

    .inner {
    	@include respond-to(large) {
    		width: 80%;
    		margin: 0 auto;
    	}
    }

    h2 {
    	color: white;
    	+ p {
    		font-weight: 400;
    		margin-bottom: 35px;
    	}
    }

    ul {
    	padding-left: 0;
    	li {
    		padding-bottom: 25px;
    	}
    }

	.info-image {
		display: none;

		@include respond-to(small) {
			display: block;
		}
	}
	.info-image-small {
		@include respond-to(small) {
			display: none;
		}
	}

    .img-wrapper {
    	position: relative;
    	margin: 0 -20px;
    	height: 200px;
    	overflow: hidden;

    	img {
    		position: absolute;
    		top: 50%;
    		left: 50%;
    		transform: translate(-50%, -50%);
    		min-width: 100%;
    	}
    	@include respond-to(small) {
    		height: auto;
    		margin: 0 auto;
    		overflow: initial;
    		img {
    			position: relative;
			    top: 0;
			    left: 0;
			    transform: none;
			    min-width: 100%;
    		}
    	}
    }

    img {
    	margin: 0 auto;
    	display: block;
    	box-shadow: 0px 0px 35px 5px rgba(0,0,0,0.6);
    }
    &.media-right,
    &.media-left {
		display: block;
        padding-left: 0;
        padding-right: 0;
    }
	
	@include respond-to(small) {
        padding: 40px 0;

		&.media-right,
	    &.media-left {
            margin-bottom: 60px;

	    	.inner {
	    		display: flex;
	    		align-items: flex-start;
	    	}

	    	.img-wrapper {
	    		align-self: flex-end;
	    		margin-bottom: -100px;
	    	}

	    	.text-wrapper {
	    		width: 55%;
	    		padding-left: 30px;
	    	}
	    }

	    &.media-right {
	    	.img-wrapper {
	    		order: 2;
	    	}
	    	.text-wrapper {
	    		padding: 0 30px 0 0;
	    	}
	    }
	}
}