// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
	font-size: 14px;
	color: white;
	overflow: hidden;
	a {
		color: white;
		@include on-event {
			color: white;
		}
	}
	.footer-top {
		display: none;
		@include respond-to(small) {
			display: block;
			background-color: rgba($brand-color, 0.92);
			padding: 30px 0;
		}
		.nav-footer {
			.nav-first-level {
				@include resetList;
				display: flex;
				justify-content: space-around;

				> li {
					flex: 1;
					a {
						text-transform: uppercase;
						font-weight: 600;
					}
				}
			}
			.nav-second-level {
				list-style: none;
				padding-left: 15px;
				margin-top: 5px;
				a {
					text-transform: none;
					font-weight: 400;
				}
			}
		}
	}

	.footer-bottom {
		background-color: $brand-color;
		text-align: center;
		padding: 25px 0;

		@include respond-to(small) {
			padding: 30px 20px;
			text-align: left;
			display: flex;
			flex-flow: row wrap;
		}
		@include respond-to(medium) {
			padding: 15px 20px;
		}

		ul {
			@include resetList;	
		}

		.copyright,
		.secondary-nav,
		.social {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1 100%;
		}

		.copyright {			
			margin-top: 15px;
			@include respond-to(small){
				margin-top: 0;
				justify-content: flex-start;
			}

			@include respond-to(large) {
				order: -2;	
				flex: 2 auto;				
			}
			
			p {
				margin: 0;
				font-size: 12px;
				font-style: italic;
				@include respond-to(medium) {
					font-size: 14px
				}
			}
		}

		.nav-secondary {
			flex-direction: column;
			border-bottom: 0 none;

			@include respond-to(small) {				
				flex-direction: row;				
				justify-content: space-between;
				flex: 1 auto;
			}

			@include respond-to(large) {
				flex: 2 auto;
			}

			li {
				display: block;
				margin-bottom: 25px;
				@include respond-to(small) {
					display: inline-block;
					padding: 0 10px;
					margin-bottom: 0;
					&:first-child {
						padding-left: 0;
					}
				}
			}
		}

		.social {
			margin-bottom: 25px;

			@include respond-to(small){
				flex: 1 auto;
				justify-content: flex-end;
				margin-bottom: 0;
			}

			@include respond-to(large) {
				flex: 0 auto;
			}

			li {
				display: inline-block;
				vertical-align: middle;
				margin-left: 15px;
			}

			a {
				display: block;
				width: 40px;
				height: 40px;
				@include respond-to(medium) {
					width: 26px;
					height: 26px;
				}
				.icon {
					fill: $lighter-blue;
					transition: all .3s ease-out;
					width: 100%;
					height: 100%;
				}
				@include on-event {
					.icon {
						fill: white;
					}
				}
			}
		}		

	}
}