.comment-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.comment-img {
  padding-right: 20px;

  img {
    width: 50px;
    height: 50px;
  }
}

.comment-text {
  h6 {
    font-size: 16px;
  }

  span {
    font-size: 14px;
  }
}

.comment-date {
  display: block;
}
