.select_wrapper {
  .select_substitute_style {
    height: auto;
    padding: 12px 45px 12px 15px;
    font-size: 20px;
    color: $link-color;
    border: 1px solid $light-blue;
    box-shadow: none;
    line-height: normal;
    border-radius: 0;
    background-image: url('#{$icon-path}/caret-down-dark-blue.svg');
    background-size: 30px 30px;
    background-position: calc(100% - 10px) center;

    &::-ms-clear {
      display: none;
    }
  }

  .options_list_style {
    @include resetList();
    top: 50px;

    .optionItem {
      padding: 15px;
      font-size: 20px;
      font-weight: lighter;
    }
  }
}


.js-emkSelect-wrapper {
  position: relative;
}