.product-extensions {
	.product-extensions-wrapper {
		display: flex;
		flex-wrap: wrap;
	}
	.card-simple {
		@include respond-to(medium) {
	        margin: 15px;
	        flex-direction: column;
	        align-items: flex-start;
	        width: calc(33.3333% - 30px);
	    }
	}
}