.garage-map {
  position: relative;
  overflow-x: hidden;

  .with-loading {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff url("../images/loading.gif") center 250px no-repeat;
      z-index: -1;
      opacity: 0;
      display: block;
    }

    &.loading {
      &::after {
        z-index: 99999;
        opacity: 1;
      }
    }
  }

  .google-map {
    width: 100%;
    height: 425px;

    @include respond-to(medium) {
      height: 905px;
    }
  }

  .initial-filters {
    background-color: #2357a2;
    margin: 0 auto;
    z-index: 10;
    color: #fff;
    padding: 25px 15px;

    @include respond-to(medium) {
      padding: 35px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 678px;
      position: absolute;
    }
  }

  .filters-title {
    font-weight: 600;
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;

    @include respond-to(medium) {
      text-align: left;
      margin-bottom: 30px;
    }
  }

  .filter-input-mobile-label {
    color: #fff;
    margin-bottom: 10px;

    @include respond-to(medium) {
      display: none;
    }
  }

  .filter-input-group {
    background: #fff;
    display: block;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;

    @include respond-to(medium) {
      margin-bottom: 10px;
    }

    label {
      color: #1a1818;
      border-right: 1px solid #cfcfcf;
      margin: 0 10px 0 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      @include respond-to(medium) {
        padding-right: 25px;
        width: 145px;
      }

      .icon {
        width: 24px;
        height: 24px;
        color: currentColor;
        margin-right: 13px;
        cursor: pointer;
      }
    }

    select,
    input {
      border: 0;
      color: #252525;
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 0 5px;
      appearance: none;

      &:focus {
        outline: none;
      }
    }

    input.pac-target-input {
      background-image: url("#{$icon-path}/input-location.svg");
      background-repeat: no-repeat;
      background-position: center right;
    }

    select {
      background-image: url("#{$icon-path}/input-select.svg");
      background-repeat: no-repeat;
      background-position: center right;
    }

    .input-empty {
      color: rgba(#252525, 0.5);

      &::placeholder {
        color: currentColor;
      }
    }
  }

  .view-listing {
    @include respond-to(medium) {
      display: flex;
      align-items: flex-start;
    }

    .listing-main {
      flex-grow: 1;
    }

    .top-strip {
      z-index: 10;
      background-color: #003684;
      position: relative;
      padding: 15px;

      @include respond-to(xlarge) {
        padding: 15px 22px 16px 80px;
      }

      .top-strip-inner {
        @include respond-to(xlarge) {
          display: flex;
          justify-content: space-between;

          & > * {
            margin: 0 11px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          & > *:nth-child(1) {
            flex-grow: 1;
          }

          & > *:nth-child(2) {
            width: 440px;
          }
        }
      }
    }

    .directions {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @include respond-to(large) {
        flex-wrap: nowrap;
      }

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.366' height='100.087' viewBox='0 0 18.366 100.087'%3E%3Cg transform='translate(-13202.336 -3503)'%3E%3Cg transform='translate(13202.336 3503)' fill='rgba(0,54,132,0)' stroke='%23fff' stroke-width='2'%3E%3Ccircle cx='9' cy='9' r='9' stroke='none'/%3E%3Ccircle cx='9' cy='9' r='8' fill='none'/%3E%3C/g%3E%3Cpath d='M45.366,734.531a1.455,1.455,0,0,1,.991.3,1.08,1.08,0,0,1,.337.854,1.155,1.155,0,0,1-.337.884,1.4,1.4,0,0,1-.991.317,1.37,1.37,0,0,1-.977-.322,1.281,1.281,0,0,1-.034-1.7A1.337,1.337,0,0,1,45.366,734.531Zm0,5.822a1.455,1.455,0,0,1,.991.3,1.08,1.08,0,0,1,.337.854,1.155,1.155,0,0,1-.337.884,1.4,1.4,0,0,1-.991.317,1.37,1.37,0,0,1-.977-.322,1.281,1.281,0,0,1-.034-1.7A1.337,1.337,0,0,1,45.366,740.353Zm0,5.822a1.455,1.455,0,0,1,.991.3,1.08,1.08,0,0,1,.337.854,1.155,1.155,0,0,1-.337.884,1.4,1.4,0,0,1-.991.317,1.37,1.37,0,0,1-.977-.322,1.281,1.281,0,0,1-.034-1.7A1.337,1.337,0,0,1,45.366,746.176Zm0,5.822a1.455,1.455,0,0,1,.991.3,1.08,1.08,0,0,1,.337.854,1.155,1.155,0,0,1-.337.884,1.4,1.4,0,0,1-.991.317,1.37,1.37,0,0,1-.977-.322,1.281,1.281,0,0,1-.034-1.7A1.337,1.337,0,0,1,45.366,752Zm0,5.822a1.455,1.455,0,0,1,.991.3,1.08,1.08,0,0,1,.337.854,1.155,1.155,0,0,1-.337.884,1.4,1.4,0,0,1-.991.317,1.37,1.37,0,0,1-.977-.322,1.281,1.281,0,0,1-.034-1.7A1.337,1.337,0,0,1,45.366,757.82Zm0,5.822a1.455,1.455,0,0,1,.991.3,1.08,1.08,0,0,1,.337.854,1.155,1.155,0,0,1-.337.884,1.4,1.4,0,0,1-.991.317,1.37,1.37,0,0,1-.977-.322,1.281,1.281,0,0,1-.034-1.7A1.337,1.337,0,0,1,45.366,763.643Z' transform='translate(13166.336 2800)' fill='%23fff'/%3E%3Cpath d='M36.634,780.179a8.872,8.872,0,0,1,17.733,0c0,6.424-8.866,15.908-8.866,15.908S36.634,786.6,36.634,780.179Zm5.7,0a3.169,3.169,0,1,0,3.167-3.06A3.115,3.115,0,0,0,42.333,780.179Z' transform='translate(13166.336 2807)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
        position: absolute;
        width: 18px;
        height: 100px;
        background-repeat: no-repeat;
        background-position: center bottom;
        top: 32px;
        left: 41px;
      }

      .destination {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        br {
          display: none;
        }
      }

      .action-close {
        display: block;
        background: transparent;
        border: 0;
        padding: 0;

        .icon {
          width: 14px;
          height: 14px;
          fill: #fff;
        }
      }

      .road-details {
        color: #fff;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 10px 0;
        width: 100%;

        @include respond-to(large) {
          width: auto;
          border-left: 1px solid #09255d;
          border-right: 1px solid #09255d;
          padding: 5px 22px;
          margin-left: 22px;
          margin-right: 22px;
        }

        .icon {
          width: 27px;
          height: 22px;
          fill: #fff;
          margin-right: 12px;
        }
      }

      .button {
        margin: 0;
      }
    }

    .list-switch {
      width: 50px;
      height: 50px;
      background: #003684 0% 0% no-repeat padding-box;
      opacity: 1;
      position: absolute;
      bottom: 20px;
      right: 20px;
      border-radius: 50%;
      border: 0;
      z-index: 500;

      &:focus {
        outline: none;
      }
    }

    .sidebar {
      flex-shrink: 0;
      flex-direction: column;
      position: absolute;
      top: 505px;
      left: 0;
      width: 100%;
      height: calc(100% - 505px);
      background: #fff;
      z-index: 105;
      display: none;
      overflow-y: auto;

      &.active {
        display: block;
      }

      @include respond-to(medium) {
        display: flex;
        position: static;
        width: 495px;
        height: 1095px;
        overflow-y: initial;
      }

      @include respond-to(xlarge) {
        height: 1016px;
      }
    }

    .filters-type {
      color: #fff;
      display: flex;
      justify-content: space-between;
      background: #2357a2;

      @include respond-to(medium) {
        background: #003684;
      }

      & > div {
        position: relative;
        flex-grow: 1;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0;
        padding: 10px;
        position: relative;
        height: 100px;
        flex-direction: column;
        text-align: center;
        font-size: 14px;

        @include respond-to(medium) {
          text-align: left;
          flex-direction: row;
          font-size: 16px;
        }

        &::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: #ffffff transparent transparent transparent;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          opacity: 0;
        }

        .icon {
          width: 30px;
          height: 30px;
          fill: #fff;
          margin-bottom: 5px;

          @include respond-to(medium) {
            margin-bottom: 0;
            margin-right: 11px;
          }
        }

        & > span {
          max-width: 150px;
        }
      }

      input:checked + label {
        color: $orange;
        background: #fff;

        &::after {
          @include respond-to(medium) {
            opacity: 1;
          }
        }

        .icon {
          fill: $orange;
        }
      }
    }

    .filters-range {
      color: #fff;
      padding: 15px 0 10px;

      @include respond-to(medium) {
        display: flex;
        padding: 18px 23px;
        align-items: center;
        background: #2357a2;
      }

      p {
        flex-shrink: 0;
        margin: 0 0 10px;

        @include respond-to(medium) {
          font-weight: 700;
          margin: 0;
          max-width: 100px;
        }
      }

      .inputs {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        &::after {
          content: "";
          position: absolute;
          bottom: 8px;
          left: 50%;
          width: calc(100% - 45px);
          height: 2px;
          background: #fff;
          transform: translateX(-50%);
        }
      }

      .input-item {
        position: relative;

        input {
          position: absolute;
          margin: 0;
          top: 0;
          left: 0;
          opacity: 0;
        }

        label {
          font-weight: 400;
          white-space: nowrap;
          position: relative;
          padding-bottom: 30px;
          margin: 0;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: #fff;
            transform: translateX(-50%);
            z-index: 1;
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 50%;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #003684;
            transform: translateX(-50%);
            z-index: 2;
            opacity: 0;
          }
        }

        input:checked + label {
          font-weight: 700;

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .garages-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    overflow-y: auto;

    li {
      padding: 16px 20px 16px 20px;
      margin: 0;
      display: flex;
      cursor: pointer;

      @include respond-to(medium) {
        padding: 16px 35px 16px 25px;
      }

      &::before {
        content: none;
      }

      &.selected,
      &:hover {
        background-color: #eef4fb;
      }

      &:not(:last-child) {
        border-top: 1px solid rgba(#cfcfcf, 0.5);
      }
    }

    .pin {
      flex-shrink: 0;
      margin-right: 15px;
      width: 18px;
      height: 24px;
      margin-top: 6px;

      @include respond-to(medium) {
        margin-top: 8px;
      }
    }

    .inner {
      flex-grow: 1;
    }

    .name {
      color: #009fe2;
      font-size: 25px;

      @include respond-to(medium) {
        font-size: 30px;
      }
    }

    .meta {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      p:first-child {
        flex-grow: 1;
      }
    }

    .phone {
      font-size: 18px;
      color: currentColor;
      font-weight: 600;
      margin-left: 10px;

      @include respond-to(medium) {
        font-size: 22px;
      }
    }

    .bottom {
      border-top: 1px solid rgba(#cfcfcf, 0.5);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      margin-top: 10px;

      button {
        background: transparent;
        border: 0;
        color: #1b3981;
        font-weight: 600;
        display: inline-flex;
        align-items: center;

        .icon {
          fill: #009fe2;
          height: 19px;
          margin-right: 20px;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .scroll-button {
    height: 38px;
    background-color: #003684;
    display: none;
    text-align: center;
    position: static;
    width: 100%;
    flex-shrink: 0;

    &:focus {
      outline: none;
    }

    @include respond-to(medium) {
      display: block;
    }

    &[disabled] {
      background-color: #93accf;
      &::after {
        background-image: url("#{$icon-path}/caret-down-dark-blue.svg");
      }
    }

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 19px;
      height: 12px;
      background-size: 19px 12px;
      background-image: url("#{$icon-path}/caret-down-light-blue.svg");
    }

    &.scroll-up {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .listing-inner {
    @include respond-to(medium) {
      position: relative;
    }
  }

  .detail-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 600;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    @include respond-to(large) {
      overflow-y: hidden;
    }

    @include respond-to(medium) {
      height: 100%;
      overflow-y: scroll;
      display: flex;
      flex-direction: row;
    }

    .icon {
      fill: $link-color;
    }

    .question {
      margin: 9px 0 24px;
      display: block;
      @include respond-to(medium) {
        display: inline-block;
      }
    }

    .detail-photo {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include respond-to(medium) {
        width: 20%;
        margin-right: 40px;
        margin-left: -20px;
        height: 100%;
      }
    }

    .detail-inner {
      flex-grow: 1;
    }

    .container-buttons {
      padding-top: 15px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      order: 2;

      @include respond-to(large) {
        padding-right: 28px;
        text-align: right;
        display: block;
      }
    }

    .detail-button {
      color: $link-color;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      background-color: transparent;
      display: inline-block;
      text-decoration: none;

      @include respond-to(medium) {
        margin-left: 30px;
      }
      .icon {
        display: block;
        width: 22px;
        height: 22px;
        margin: 0 auto 10px;
      }

      &.copy-url {
        position: relative;

        .confirm {
          width: 100%;
          padding: 7px 5px;
          border-radius: 10px 0 10px 0;
          color: $white;
          background: $light-blue;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .detail-title {
      color: $link-color;
      font-size: 30px;
      margin: 0 0 27px;
      letter-spacing: -0.3px;
      text-align: left;
    }

    .detail-description {
      letter-spacing: 0.67px;
      max-width: 690px;
      margin-bottom: 30px;
    }

    .info-item {
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px;

      .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
      }

      .icon-arrow {
        right: 0;
        left: auto;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        fill: $link-color;
        width: 7px;
        height: 11px;
        margin-left: 10px;
      }

      button,
      a {
        padding: 0;
        border: 0;
        background: transparent;
        font-weight: 600;
        word-wrap: break-word;
        color: $link-color;
      }

      &.with-break-line {
        span {
          display: block;
        }
      }
    }

    .details-bottom {
      @media screen {
        padding-top: 40px;
        margin-top: 20px;
        border-top: 1px solid rgba(#a9aaaa, 0.25);
      }
    }
  }

  .info-window-content {
    padding: 10px;
    min-width: 350px;

    .info-title {
      color: $link-color;
      font-size: 15px;
      margin: 0;
      display: block;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 2px;

      @media screen and (max-width: 360px) {
        white-space: initial;
        width: auto;
        text-overflow: initial;
        overflow: visible;
      }

      @include respond-to(mobile) {
        font-size: 30px;
      }

      @include respond-to(medium) {
        font-size: 15px;
      }

      @include respond-to(large) {
        font-size: 30px;
      }
    }

    .info-address {
      color: $grey;
      font-size: 12px;
      display: block;
      margin-bottom: 4px;
      line-height: 14px;

      br {
        display: none;
      }

      @include respond-to(mobile) {
        font-size: 16px;
        line-height: 20px;
      }

      @include respond-to(medium) {
        font-size: 12px;
        line-height: 14px;
      }

      @include respond-to(large) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .info-number {
      font-size: 11px;
      font-weight: 500;
      color: $link-color;
      display: block;

      @include respond-to(mobile) {
        font-size: 16px;
        line-height: 20px;
      }

      @include respond-to(medium) {
        font-size: 11px;
        line-height: 1.2;
      }

      @include respond-to(large) {
        font-size: 16px;
        line-height: 20px;
      }

      .icon {
        fill: $link-color;
        height: 11px;
        width: 11px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-bottom: 3px;
        @include respond-to(mobile) {
          height: 16px;
          width: 16px;
        }
        @include respond-to(medium) {
          height: 11px;
          width: 11px;
        }
        @include respond-to(large) {
          height: 16px;
          width: 16px;
        }
      }
    }

    .actions {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      flex-wrap: nowrap;
      align-items: flex-end;
      margin-top: 30px;

      button {
        flex-basis: inherit;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .info-button {
      background: transparent;
      color: #1b3981;
      font-size: 10px;
      font-family: $text-font-stack;
      font-weight: 600;
      height: 1.3em;
      padding: 0;
      border: 0;

      @media screen and (max-width: 360px) {
        width: 100%;
        text-align: left;
        margin-bottom: 3px;
      }
      @include respond-to(mobile) {
        font-size: 16px;
      }
      @include respond-to(medium) {
        font-size: 10px;
      }
      @include respond-to(large) {
        font-size: 16px;
      }

      .icon {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        fill: $brand-color;
        margin-bottom: -3px;
        @include respond-to(mobile) {
          height: 19px;
          width: 19px;
        }
        @include respond-to(medium) {
          height: 14px;
          width: 14px;
        }
        @include respond-to(large) {
          height: 19px;
          width: 19px;
        }
      }
    }
  }

  @media print {
    .print-hide {
      display: none !important;
    }

    .detail-view {
      position: relative;
    }

    .detail-view .detail-title {
      text-align: left;
    }

    .detail-view .icon {
      left: 0 !important;
    }
  }
}
