.job-detail {
  .card {
    position: relative;
    margin: 44px 0 20px;
  }

  .icon-print {
    display: none;
    @include respond-to(medium) {
      display: block;
      position: absolute;
      right: 30px;
      border: 1px solid $grey;
      fill: $grey;
    }
  }

  .job-detail-content {
    margin-bottom: 40px;

    @include respond-to(small) {
      padding: 0 20px;
    }
  }

  .cta-wrapper {
    margin-top: 40px;
    text-align: center;
    .cta-rounded {
      font-size: 24px;
      font-weight: 300;
      padding: 15px 28px;
    }
  }
}
