.compare-products {
  .container {
    position: relative;

    @include respond-to(medium) {
      font-size: 0;
    }
  }

  .compare-title {
    margin-bottom: 0;

    @include respond-to(medium) {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }

    .highlight {
      display: inline-block;
      margin-bottom: 10px;

      @include respond-to(medium) {
        margin: 0;
      }
    }

    .select-product {
      width: 100%;
      min-width: 150px;
      font-size: 34px;
      font-weight: 400;
      color: $link-color;

      @include respond-to(medium) {
        width: auto;
        margin-left: 20px;
      }
    }

    .select_wrapper {
      @include respond-to(medium) {
        margin-left: 20px;
      }
    }

    .select_substitute_style {
      font-size: 26px;
      color: $link-color;
    }

    .options_list_style {
      z-index: 300;
      top: 61px;
    }

    .optionItem {
      font-size: 26px;
    }
  }

  .criteria-list, .slide {
    @include respond-to(medium) {
      font-size: 16px;
    }
  }

  .slider-legend {
    position: relative;
    width: 50%;
    display: inline-block;

    @include respond-to(medium) {
      width: 25%;
    }
  }

  .criteria-list {
    position: absolute;
    z-index: 10;
    top: 110px; // Offset the additional height of the product criteria
    left: 1px;
    width: 100%;
    background-color: #fff;

    @include respond-to(medium) {
      position: relative;
      top: -64px;
      left: 0;
      display: inline-block;
    }

    .criteria {
      position: relative;
      display: flex;
      align-items: center;
      height: 64px; // All criteria should take the same height because some will wrap to 2 lines
      padding: 20px;
      
      &::after {
        content: "";
        position: absolute;
        left: 20px;
        bottom: 0;
        height: 1px;
        width: calc(200% - 40px);
        background-color: $lighter-grey;

        @include respond-to(medium) {
          width: calc(100% - 40px);
        }
      }
    }
  }

  .slider {
    border: 1px solid #ddeaf7;

    @include respond-to(medium) {
      width: 75%;
      display: inline-block;
      border: none;
    }
  }

  ul {
    @include resetList();
  }

  .slide-wrapper {
    @include respond-to(medium) {
      border: 1px solid #ddeaf7;
      box-shadow: 0px 0px 10.32px 1.68px rgba(0, 29, 71, 0.1);
    }

    h3 {
      height: 105px;
      margin: 0;
      padding: 20px 40px 40px;
      background-color: $light-bg;
      text-align: center;
      font-size: 20px;
      font-weight: 600;

      @include respond-to(medium) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 40px;
      }
    }
  }

  .slide {
    @include respond-to(medium) {
      width: calc(33.3333% - 20px);
      display: inline-block;
      margin: 0 10px;
    }
  }

  .product-criteria-list {
    width: 50%;
    margin-left: 50%;
    text-align: center;

    @include respond-to(medium) {
      width: 100%;
      margin-left: 0;
    }
  }

  .product-criteria, .product-info {
    height: 64px; // Product criteria should take the same height as the criteria list items
    padding: 20px;
  }

  .product-criteria {
    color: $grey;

    @include respond-to(medium) {
      &::after {
        content: "";
        position: absolute;
        left: 20px;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        background-color: $lighter-grey;
      }
    }

    .icon {
      height: 40px;
      width: 40px;
      margin-top: -10px;
      fill: $green;
    }
  }

  //.slick-track, .slide.slick-active {
  //  min-width: 100%;
  //}

  .product-info {
    transform: translateX(-50%);

    @include respond-to(medium) {
      transform: none;
    }
  }

  .slick-prev, .slick-next {
    top: 25px;
    z-index: 20;
    transform: none;

    &::before {
      content: "";
      position: absolute;
      opacity: 1;
      height: 30px;
      width: 30px;
      background-image: url("../images/icons/caret-right-blue.svg");
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    &.slick-disabled {
      &::before {
        opacity: .2;
      }
    }
  }

  .slick-prev {
    &::before {
      left: 25px;
      transform: rotate(180deg);
    }
  }

  .slick-next {
    right: 20px;
  }

  .slick-dots {
    position: absolute;
    z-index: 100;
    top: 75px;
    width: 100%;

    li {
      height: 12px;
      width: 12px;
      margin: 0 3px;
      border-radius: 50%;
      border: 1px solid $link-color;

      button {
        &::before, &::after {
          content: none;
        }
      }

      &.slick-active {
        background-color: $link-color;
      }
    }
  }
}