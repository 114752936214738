.news-list, .search-results, .search-results-linked {
  padding-top: 0;

  .filter-items{
    display: flex;
    flex-direction: column;
  }

  .results-found {
    padding: 10px 0;
    margin-bottom: 0;
    border-bottom: 1px solid $light-grey;
  }

  .linked-found {
    padding: 10px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid $light-grey;
  }

  .news-item, .search-item {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 30px;
    border-bottom: 1px solid $lighter-grey;
    position: relative;
    span.link{
      display: none;
    }
    span.link.download{
      display: block;
      font-weight: bold;
      color: $link-color;
      svg{
        fill: $link-color;
        margin-right: .5em;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
      }
    }
    &.highlight{
      background: $lighter-blue;
      border-bottom: 1px solid $lighter-blue;
      &::before {
        background: $light-blue;
      }
    }

    @include respond-to(small) {
      min-height: 200px;
      &:hover{
        background: $lighter-grey;
        border-bottom: 1px solid $lighter-grey;
      }
    }
  }

  .address,
  .numbers {
    display: flex;
    font-weight: normal;
    color: inherit;
    margin: 10px 0;
    line-height: 1.2;
    position: relative;
    .icon {
      width: 17px;
      height: 17px;
      fill: $link-color;
      margin-right: .25em;
    }
  }

  .numbers{
    color: $link-color;
  }

  .search-item {
    &::before {
      content: "";
      float: left;
      height: 20px;
      width: 20px;
      padding: 40px;
      background: $lighter-blue;
      margin: 0 20px 20px 0;
      @include respond-to(small) {
        height: 150px;
        width: 150px;
        margin-right: 40px;
      }
    }
    &.product::before{
      content: url("#{$icon-path}/placeholder-product.svg");
    }
    &.office::before{
      content: url("#{$icon-path}/placeholder-bureau.svg");
    }
    &.newsItem::before{
      content: url("#{$icon-path}/placeholder-news.svg");
    }
    &.faq::before{
      content: url("#{$icon-path}/placeholder-faq.svg");
    }
    &.page::before{
      content: url("#{$icon-path}/placeholder-page.svg");
    }
    &.document::before{
      content: url("#{$icon-path}/placeholder-document.svg");
    }
    &.advisor::before{
      content: url("#{$icon-path}/placeholder-individual.svg");
    }

  }

  .search-media {
    position: absolute;
    left: 30px;
    top: 30px;
    height: 100px;

    @include respond-to(small) {
      height: 150px;
      margin-right: 40px;
    }
  }

  .search-media-img{
    position: absolute;
    left: 30px;
    top: 30px;
    height: 100px;
    width: 100px;
    background-color: $lighter-blue;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    @include respond-to(small) {
      height: 150px;
      width: 150px;
      margin-right: 40px;
    }
    .search-media {
      left: 50%;
      top: 50%;
      height: auto;
      width: auto;
      max-height: 100%;
      transform: translate(-50%,-50%);
      margin: 0;
      @include respond-to(small) {
        height: auto;
        margin: 0;
      }
    }
  }

  .news-media {
    float: left;
    height: 100px;
    margin: 0 20px 20px 0;

    @include respond-to(small) {
      height: 150px;
      margin-right: 40px;
    }
  }

  .search-infos{
    background: $orange;
    font-size: 12px;
    line-height: 1em;
    padding: 5px 10px;
    color: white;
    position: absolute;
    top: 20%;
    left: 0;
  }

  .news-content, .search-content {
    clear: both;

    @include respond-to(small) {
      clear: none;
    }
  }

  h3 {
    margin-top: 10px;
    color: $text-color;
    font-weight: bold;
  }

  .link, .link-download {
    margin-top: 10px;
  }

  .news-date, .search-date, .search-tag,.search-type {
    color: $brand-color;
    font-size: 12px;
  }

  .search-type,
  .search-tag {
    display: inline-block;
    margin-bottom: 5px;
    padding: 2px 10px;
    border: 1px solid $brand-color;
    text-transform: uppercase;
    background: white;
  }
  .search-tag {
    color: white;
    background-color: $brand-color;
    border-color: $brand-color;
    &.individual{
      color: white;
      background-color: #{map-get($themes, individual)};
      border-color: #{map-get($themes, individual)};
    }
    &.freelance{
      color: white;
      background-color: #{map-get($themes, freelance)};
      border-color: #{map-get($themes, freelance)};
    }
    &.company{
      color: white;
      background-color: #{map-get($themes, company)};
      border-color: #{map-get($themes, company)};
    }
    &.construction{
      color: white;
      background-color: #{map-get($themes, construction)};
      border-color: #{map-get($themes, construction)};
    }
  }
  .search-type{
      &::before{
        display: inline-block;
        vertical-align: sub;
        height: 1.2em;
        width: 1.2em;
        margin-right: .25em;
      }
      &.product::before{
        content: url("#{$icon-path}/thumb-product.svg");
      }
      &.office::before{
        content: url("#{$icon-path}/thumb-bureau.svg");
      }
      &.newsitem::before{
        content: url("#{$icon-path}/thumb-news.svg");
      }
      &.faq::before{
        content: url("#{$icon-path}/thumb-faq.svg");
      }
      &.page::before{
        content: url("#{$icon-path}/thumb-page.svg");
      }
      &.document::before{
        content: url("#{$icon-path}/thumb-document.svg");
      }
      &.advisor::before{
        content: url("#{$icon-path}/thumb-individual.svg");
      }
  }

  .error-msg{
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin: 15px 0 35px 0;
    display: none;

    &.visible{
      display: block;
    }
  }

  .search-head{
    .search-date{
      padding: 5px;
      float: right;
    }
  }

  @include respond-to(small) {
    .linked-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -10px;
      .search-item {
        margin: 0 10px;
        width: calc(50% - 20px);
      }
    }
  }
}

.search-results-linked{
  padding-right: 15px;
  padding-left: 15px;
}

.filter-header{
  h2.results-found{
    border-bottom: none;
  }
}

.filter-col{
  background: white;
}
.results-col{
  &[data-visible='0'] + .search-results-linked,
  &[data-visible='1'] + .search-results-linked,
  &[data-visible='2'] + .search-results-linked{
    @include respond-to(small) {
      .linked-items {
        .search-item {
          margin: 0 10px;
          width: 100%;
        }
      }
    }

  }
}

[id=searchContainer]{
  position: relative;
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff url("../images/loading.gif") center 250px no-repeat;
    z-index: -1;
    opacity: 0;
	display: block;
    // transition: opacity .5s ease;
  }
  &.loading{
    &::after {
      z-index: 99999;
      opacity: 1;
    }
  }
}





