// This module uses styles defined in components/_panel-toggle.scss
.howHelp {
	background-color: $light-bg;
	h2 {
		font-size: 32px;
		font-weight: 600;
		color: $text-color;
		text-transform: uppercase;
		text-align: center;
	}

	.panels-wrapper {
		@include respond-to(medium) {
			padding: 0 0 20px 0;
		}

		.nav-tabs {
			@include respond-to(medium) {
				border-bottom: 1px solid $light-blue;
			}
			h3 {
				font-size: 20px;
			}
			a {
				color: $link-color;
			}
		}
	}

	.panel {
		background-color: $light-bg;
		border-top: 3px solid #fff;

		@include respond-to(medium) {
			border: none;
		}

		&.active {
			.panel-content {
				@include respond-to(medium) {
					display: flex;
					flex-grow: 1;
				}
			}
		}
		.col h4:first-child {
			margin-top: 10px;
			@include respond-to(medium){
				margin-top: 40px;
			}
		}

		h4 {
			font-size: 20px;
			font-weight: 400;
			margin: 30px 0 20px;
			@include respond-to(medium) {
				min-height: 22px;
				margin: 20px 0 20px;
			}
			a {
				color: $brand-color;
			}
		}
		p {
			font-size: 16px;
			&.color-light {
				color: $grey;
			}
			+ .cta-rounded,
			+ .link {
				margin-top: 10px;
			}
		}
		strong {
			font-weight: 600;
		}
		.link {
			color: $brand-color;
			display: inline-block;
			margin-top: 20px;
		}
		.cta-rounded {
			margin: 20px 0 35px;
		}
		ul.list-link {
			@include resetList;
			li {
				padding-bottom: 20px;
				.cta-rounded,
				.link {
					margin: 0;
				}
			}
		}
		.panel-title {
			font-weight: 400;
			font-size: 20px;
			text-transform: uppercase;
			color: $link-color;
		}
	}
}