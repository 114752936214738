.cookie-banner {
  display: none; // Visibility toggled in JS
  position: fixed;
  bottom: 0;
  z-index: 500;
  width: 100%;
  padding: 30px 20px;
  background-color: rgba($dark-grey, .9);
  color: #fff;
  
  &.visible {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  @include respond-to(small) {
    flex-wrap: nowrap;
    background-color: $dark-grey;
  }

  .cookie-message {
    margin: 0 0 20px 0;

    @include respond-to(small) {
      margin: 0 20px 0 0;
    }
  }

  .cta-wrapper {
    display: flex;
    align-items: center;
    margin: 0;
  }

  a {
    margin-right: 15px;
    margin-bottom: 0;
    white-space: nowrap;
  }
}