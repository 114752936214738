.video-banner {
  position: relative;
  padding: 0;
  font-size: 0;

  video {
    position: relative;
    max-height: 600px;
    width: 100%;
    cursor: pointer;
    background-color: #000;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.3);
    pointer-events: none;
    background-size: cover;
    background-position: center;
  }

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: #fff;
  }

  .icon {
    margin-top: 10px;
    height: 60px;
    width: 60px;
    fill: #fff;
    cursor: pointer;

    @include respond-to(small) {
      margin-top: 40px;
      height: 100px;
      width: 100px;
    }
  }
}
