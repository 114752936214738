.quote {
  padding: 50px 0;

  @include respond-to(small) {
    padding: 70px 0;
  }

  .quote-wrapper-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  blockquote {
    margin: 0 10px;
    padding: 30px;
    display: inline-block;
    border: 1px solid $lighter-blue;

    @include respond-to(small) {
      margin: 0;
      padding: 55px;
    }
  }

  blockquote {
    background-color: #fff;
    @include respond-to(small) {
      width: 50%;
    }
  }

  .quote-wrapper-inner {
    @extend .clearfix;
  }

  .quote-content {
    position: relative;
    font-size: 20px;

    &:before,
    &:after {
      content: '"';
      position: absolute;
      font-size: 60px;
      font-style: italic;
    }

    &:before {
      top: -35px;
      left: -35px;
    }

    &:after {
      right: 0;
    }
  }

  .quote-author {
    float: right;
    /* inline-block is ignored due to the float. If 'float' has a value other than 'none', the box is floated and 'display' is treated as 'block' */
    // display: inline-block;
    margin-top: 40px;
    color: $brand-color;

    &:before {
      content: none;
    }
  }

  .quote-author-name {
    font-style: italic;
  }

  .cta-wrapper {
    margin-top: 40px;
  }

  .cta {
    margin: 10px 10px 0 0;
    display: block;
    text-align: center;

    @include respond-to(medium) {
      display: inline-block;
    }
  }

  .quote-media {
    position: relative;
    max-width: 100%;
    font-size: 0;

    @include respond-to(small) {
      order: 1; // Order inversed on desktop
      position: relative;
      right: 20px;
      flex-basis: 50%;
    }

    img,
    video {
      max-width: 100%;
      box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
    }

    video {
      position: relative;
      max-width: 100%;
      cursor: pointer;

      @include on-event {
        + .icon {
          fill-opacity: 0.7;
        }
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: hsla(0, 0%, 0%, 0.3);
      pointer-events: none;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100px;
      width: 100px;
      fill: #fff;
      fill-opacity: 0.5;
      cursor: pointer;

      @include on-event {
        fill-opacity: 0.7;
      }
    }
  }
}
