// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $text-font-stack;
  font-weight: 300;
  color: $text-color;
  font-size: 16px;
}

/**
 * Basic styles for links
 */
a {
  color: $link-color;
  text-decoration: none;

  @include on-event {
    color: $link-color;
    text-decoration: underline;
  }
}

.icon {
  height: 25px;
  width: 25px;
}
.promotion {
  &:before {
    @include iconCss(promotion, 20px, 20px);
    vertical-align: sub;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: $brand-color;
  word-wrap: break-word;
  hyphens: none;

  .highlight {
    display: block;
    font-weight: 400;
    hyphens: none;
  }
}
h1 {
  font-size: 18px;
  .highlight {
    font-size: 40px;
    line-height: 1.2;
  }
  .subtitle {
    font-size: 24px;
    line-height: 24px;
  }
  @include respond-to(medium) {
    font-size: 34px;
    .highlight {
      font-size: 45px;
    }
  }
}
h2 {
  margin-bottom: 30px;
  font-size: 20px;
  .highlight {
    font-size: 34px;
  }
  @include respond-to(small) {
    font-size: 22px;
    .highlight {
      font-size: 36px;
    }
  }
}
h3 {
  font-size: 16px;
  .highlight {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}

strong,
b {
  font-weight: 600;
}
ul {
  padding-left: 20px;
  li {
    list-style-type: none;
    position: relative;
    margin-bottom: 10px;
    padding-left: 15px;
    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $link-color;
      position: absolute;
      top: 8px;
      left: 0;
    }
  }
  &.checked {
    padding-left: 0px;
    li {
      list-style-type: none;
      position: relative;
      padding: 0 0 20px 30px;

      @include respond-to(small) {
        padding: 0 0 20px 50px;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 25px;
        height: 20px;
        background: url('#{$icon-path}/check.svg') center center no-repeat;
        background-size: 100%;
        position: absolute;
        top: 1px;
        left: -5px;

        @include respond-to(small) {
          width: 33px;
        }
      }
    }
  }
}

.content-inner {
  max-width: 750px;
  margin: 0 auto;
}

section {
  padding: 40px 0;
}

.section-title,
.page-title {
  padding: 40px 0 0;

  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }
}

iframe {
  width: 100%;
  border: 0 none;
}

.container.no-padding {
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
