.numbers-wrapper {
	display: flex;
	flex-wrap: wrap;
	.number {
		font-weight: 400;
		text-align: center;
		width: 100%;
		padding: 15px 5px;
		@include respond-to(small) {
			width: 50%;
		}
		@include respond-to(medium) {
			width: 33.3333%;
			padding: 30px 15px;
		}
		.value,
		.symbol,
		.value-description {
			color: $link-color;
		}
		.value {
			font-size: 34px;
			@include respond-to(medium) {
				font-size: 38px;
			}
		}
		.symbol {
			font-size: 20px;
			@include respond-to(medium) {
				font-size: 24px;
			}
		}
		.value-description {
			font-weight: 300;
			font-size: 20px;
			margin: -5px 0 10px;
		}
	}
}