.card {
    margin-bottom: 20px;
    padding: 20px;
    background-color: $light-bg;
    border-radius: 20px 0 20px 0;
    @include respond-to(medium) {
        margin-bottom: 0;
        padding: 30px;
    }

    .category {
        text-transform: uppercase;
        font-size: 14px;
        color: $brand-color;
    }

    h3 {
        font-size: 22px;
        font-weight: 600;
        color: $text-color;
        margin: 20px 0 0;
        @include respond-to(large) {
            margin: 30px 0 0;
            font-size: 26px;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-top: 0;
        + .description {
            margin-top: 20px;
        }
    }

    .icon {
        fill: $link-color;
    }

    .link, .cta, .cta-rounded {
        margin-top: 20px;
        width: auto;
    }

    .location {
        margin-top: 10px;
        .icon,
        p {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            color: $light-blue;
        }
        .icon {
            fill: $light-blue;
            width: 15px;
            height: 20px;
        }
        p {
            font-size: 14px;
        }
    }

    .description {
        margin-top: 20px;

        @include respond-to(small) {
            margin-top: 30px;
        }

        p {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    + .card {
        margin-top: 20px;
    }
}

.card-simple {
    padding: 20px;
    border: 1px solid $brand-color;
    background-color: white;
    margin: 15px 0;

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: $brand-color;
        margin: 0 0 15px;
    }
}