.segment-tag, .news-segment {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding: 7px 10px 6px;
  @include respond-to(medium){
    padding: 5px 10px;
  }
  &.individual {
    background-color: #{map-get($themes, individual)};
  }

  &.freelance, &.company {
    background-color: #{map-get($themes, company)};
  }

  &.construction {
    background-color: #{map-get($themes, construction)};
  }
}