.filter-header{
  padding-top: 0;
}

.title-filter{
  @include respond-to(medium) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  border-bottom: 1px solid $light-grey;
  color: $light-grey;
  margin-bottom: 35px;
  padding-bottom: 5px;
  h2{
    margin: 0;
  }
  button{
    background: none;
    font-weight: 400;
    color: $link-color;
    &.active{
      color: $brand-color;
    }
  }
}

.filter-header-items{
  margin: 0 -10px;
  display: flex;
  flex-direction: column;
  @include respond-to(medium) {
    flex-direction: row;
    justify-content: space-between;
  }

  .search-item{
    flex-basis: 100%;
    display: block;
    position: relative;
    border: 1px solid $dark-blue;
    color: inherit;
    text-decoration: none;
    padding: 30px 30px 50px;
    margin: 10px 10px 30px;
	-ms-flex: 1 1 auto;
    @include respond-to(medium) {
      margin: 0 10px 0 10px;
      max-width: 50%;
      &:hover {
        background: $lighter-grey;
        border-color: $light-grey;
      }
    }
  }
  .search-media,
  .search-head{
    display: none;
  }

  .search-infos{
    background: $orange;
    font-size: 12px;
    line-height: 1em;
    padding: 5px 10px;
    color: white;
    position: absolute;
    top: 0;
    left: -1px;
    transform: translateY(-50%);
  }

  h3 {
    font-size: 22px;
    margin-top: 0;
    color: $brand-color;
    font-weight: bold;
  }

  .link{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    display: block;
    background: $link-color;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    white-space: nowrap;
    svg{
      display: none;
    }
  }

}