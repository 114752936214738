.offices-advisors {
    padding: 0;
    // Default colors
    .contact-results .location-details h2 {
        color: $link-color;
    }
    .icon {
        fill: $link-color;
    }
    // if body has Class > apply segment color
    .contact-results .location-details h2 {
        $parameters: color;
        @include autoThemeColor($parameters, 'contact');
    }
    .icon {
        $parameters: fill;
        @include autoThemeColor($parameters, 'contact');
    }    
}

.contact-results {
	padding: 50px 0 0 20px;
	position: relative;
    overflow: hidden;
    border-radius: 0 0 20px 0;

    @include respond-to(small) {
        padding: 80px 0 0 40px;
    }

	+ h3 {
        margin-top: 70px;
    }
	// Background
    .main-contact-wrapper {
        background-color: $light-bg;
    }
    
    .location-details {
		background-color: $light-bg;
    	@include respond-to(medium) {
    		background-color: white;
    	}

    	h2 {
    		padding: 0;
    		margin: 0 0 15px;            
			@include respond-to(medium) {
				font-size: 38px;
			}
    	}
    }
    .contact-details {
		background-color: $light-bg;
    }

    .employees-list-wrapper {
    	background-color: $light-bg;
        padding: 15px 20px 40px;
    }

    // ICONS
    .icon-wrapper {
        .icon,
        .address,
        .email,
        .opening-hours-list,
        .contact-numbers,
        .employees-list-title {
            display: inline-block;
            vertical-align: top;
        }        
        
        .address,
        .email,
        .opening-hours-list,
        .contact-numbers,
        .employees-list-title {
            margin-top: 3px;
        }
    }
    .icon {
        margin-right: 10px;
    }

    .icon-opening-hours,
    .icon-location {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    .icon-location {
        margin-left: -5px;
    }

    .icon-contact {
        width: 20px;
        height: 20px;
        margin-right: 15px;
    }

    // FIRST BLOC
    .main-contact-wrapper {
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        @include respond-to(medium) {
            flex-direction: row;
        }
    }

    .img-wrapper {
		@include border-color(bottom, $link-color);
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.15);
        border-bottom: 1px solid white;
        margin: -20px 0 44px -20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
		width: 100%;
        height: 200px;

        @include respond-to(small) {
            margin: -40px 0 44px -40px;
        }

        @include respond-to(medium) {
            width: 380px;
            height: auto;
            margin-bottom: 4px;
        }

        &.sales-rep {
            position: relative;
            z-index: 10;
            height: 100px;
            width: 66px;
            margin: 0 0 44px -20px;

            + .wrapper-details {
                width: 100%;

                .location-details {
                    position: relative;
                    padding-bottom: 20px;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: -50px;
                        height: 18px;
                        width: 50px;
                        background-color: #fff;
                    }
                }
            }
        }

		img {
			width: 100%;
		}

		+ .wrapper-details {
			// If image
            @include respond-to(medium) {
                width: calc(100% - 340px);
            }
		}
	}

    .wrapper-details {
		display: flex;
		flex-direction: column;
        width: 100%;
    }

    .location-details,
    .contact-details {
    	padding: 0 20px;
        @include respond-to(small) {
            padding: 0 40px;
        }
    }

    .location-details {
        padding-bottom: 20px;
        @include respond-to(small) {
    	   padding-bottom: 40px;
        }
    }

    .contact-details {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    	padding-top: 0;
        @include respond-to(medium) {
            padding-top: 40px;
        }
    	.cta-rounded {
    		margin-top: 20px;
    	}
    }

    .contact {
        .icon-wrapper + .icon-wrapper {
            margin-top: 10px;
        }

        .icon-wrapper.hidden + .icon-wrapper {
            margin-top: 0px;
        }
        .link {
            color: $brand-color;
        }
    }

    .contact, 
    .opening-hours {
    	flex-grow: 1;
        width: 100%;
        @include respond-to(medium) {
    	   width: 50%;
        }
    }

    .opening-hours {
        align-self: flex-start;
        padding-top: 40px;
        @include respond-to(medium) {
            padding-top: 0;
        }
    }

    .opening-hours-list {
        max-width: calc(100% - 40px);
    }

    .sales-rep-message {
        width: 50%;
        flex-grow: 1;
    }

    // EMPLOYEES's bloc
    .employees-list {
        display: flex;
        padding: 25px 0 0 0;
        flex-wrap: wrap;

        .employee {
            display: flex;
            width: 100%;
            padding: 0 20px;
            
            &:last-child {
                padding-bottom: 0;
            }

            @include respond-to(medium) {
                width: 33.3333%;

            }

            img {
                margin-right: 15px;
                box-shadow: 0px 0px 18px 5px rgba(0,0,0,0.15);
                /*width: 70px;
                height: 70px;
                @include respond-to(small) {
                    width: 100px;
                    height: 100px;
                }
                @include respond-to(large) {
                    width: 140px;
                    height: 140px;
                }*/
            }

            .js-employee-FirstName,
            .js-employee-Name {
                display: block;
            }
        }
    }
}

