// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
a,
button {
	border: none;

	&.link,
	&.link-prev,
	&.cta,
	&.cta-rounded,
	&.cta-rounded-blue,
	&.cta-inverse {
		.icon {
			height: 12px;
			width: 12px;
			fill: currentColor;
			&.icon-caret {
				transform: rotate(-90deg);
				margin-left: 10px;
			}
		}
	}

	&.link, &.link-prev {
		display: block;
		.icon {
			fill: $link-color;
		}
	}

	&.link {
		.icon {
			transform: rotate(-90deg);
		}
	}

	&.link-prev {
		.icon {
			transform: rotate(90deg);
		}
	}

	&.link-download {
		display: inline-block;
		font-weight: 600;
		.icon {
			width: 20px;
			height: 20px;
			vertical-align: text-top;
			fill: currentColor;
		}
	}

	&.cta, &.cta-inverse {
		display: inline-block;
		padding: 12px 15px;
		transition: background-color .3s ease-out;
		line-height: 1.15;
		@include on-event {
			text-decoration: none;
		}
		.icon {
			transform: rotate(-90deg);
		}
	}

	&.cta {
		background-color: $link-color;
		color: white;
		transition: background-color .3s ease-out;
		margin: 0 0 10px;
		@include on-event {
			background-color: lighten($link-color, 10%);
		}
		.icon {
			fill: white;
		}
	}

	&.arrow {
		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 7px;
			height: 11px;
			background: url('#{$icon-path}/caret-right-blue.svg') center center no-repeat;
			background-size: contain;
			margin-left: 15px;
		}
		&.cta,
		&.cta-rounded,
		&.cta-rounded-blue {
			&:after {
				background-image: url('#{$icon-path}/caret-right-white.svg');
			}
		}

		&.cta-rounded.cta-inverse {
			&:after {
				background-image: url('#{$icon-path}/caret-right-orange.svg');
			}
			@include on-event {
				&:after {
					background-image: url('#{$icon-path}/caret-right-white.svg');
				}
			}
		}

		&.cta-inverse {
			&:after {
				background-image: url('#{$icon-path}/caret-right-blue.svg');
			}
			@include on-event {
				&:after {
					background-image: url('#{$icon-path}/caret-right-white.svg');
				}
			}
		}
	}

	&.cta-rounded, &.cta-rounded-blue {
		display: inline-block;
		background-color: $orange;
		padding: 15px 18px;
		color: white;
		border-radius: 10px 0 10px 0;
		transition: background-color .3s ease-out;

		@include on-event {
			color: white;
			text-decoration: none;
			background-color: lighten($orange, 10%);
		}
		&.warning {
			&:before {
				@include iconCss(warning, 24px, 24px);
				margin-right: 10px;
			}
		}
		&.question {
			&:before {
				@include iconCss(question, 24px, 26px);
				margin-right: 10px;
				margin-left: -3px;
			}
		}
		&.pin {
			&:before {
				@include iconCss(pin, 26px, 26px);
				margin-right: 7px;
				margin-left: -5px;
			}
		}

		&.cta-inverse {
			color: $orange;
			border-color: $orange;
			@include on-event {
				background-color: $orange;
				color: white;
			}
		}
	}

	&.cta-rounded-blue {
		background-color: $link-color;
		@include on-event {
			background-color: lighten($link-color, 10%);
		}
		&.cta-inverse {
			color: $link-color;
			border-color: $link-color;
			@include on-event {
				background-color: $link-color;
				color: white;
			}
		}
	}

	&.cta-inverse {
		background-color: transparent;
		color: $link-color;
		border: 1px solid $link-color;
		transition: background-color .3s ease-out;
		.icon {
			fill: currentColor;
		}

		@include on-event {
			background-color: $link-color;
			color: white;

			.icon {
				fill: white;
			}
		}
	}

	&.prev, &.next {
	    display: inline-block;
	    margin-right: 5px;
	    padding: 10px 8px;
	    background-color: $link-color;
	    transition: background-color .3s ease-out;
	    @include respond-to(small) {
	    	margin-right: 10px;
	    }

	    .icon {
	      height: 10px;
	      fill: #fff;
	    }

	    &.next {
	    	margin-left: 5px;
	    	margin-right: 0px;
	    	@include respond-to(small) {
				margin-left: 10px;
	    	}
	    }

	    @include on-event {
			background-color: lighten($link-color, 10%);
		}
	}

	&.prev .icon {
	    transform: rotate(90deg);
	}

	&.next .icon {
	    transform: rotate(-90deg);
	}
}