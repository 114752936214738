.advantages {
	margin-top: 25px;
	@include respond-to(large) {
		width: 85%;
	}

	ul {
		@include resetList();
		li {
			padding-bottom: 25px;
			@include respond-to(small) {
				padding-left: 60px;
				position: relative;
			}
		}
	}
	
	.icon {
		// fill: $link-color;
		fill:none;
		stroke: $link-color;
		stroke-miterlimit:10;
		stroke-width:6px;
		width: 30px;
		height: 30px;
		position: relative;
		top: 5px;
		left: 0;
		@include respond-to(small) {
			width: 40px;
			height: 40px;
			padding-top: 0;
			position: absolute;
			left: 0;
		}
		
	}
	h3 {
		display: inline-block;
		color: $link-color;
		font-size: 24px;
		font-weight: 300;
		margin-top: 0;
		hyphens: none;

		.highlight {
			display: inline-block;
			font-weight: 300;
			@include respond-to(small) {
				font-size: 38px;
			}
		}
	}
}