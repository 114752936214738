// This module overrides styles defined in components/_card.scss
.job-teaser {
	.card {
		padding: 30px;
		padding-top: 18px;
		padding-bottom: 18px;
		margin: 20px 0;
		width: 100%;
		@include respond-to(small) {
			position: relative;
			margin: 10px 0;
		}
	}

	.description {
		@include respond-to(small) {
			margin-top: 0;
			padding-right: 30px;
		}

		h3 {
			margin-top: 0;
			
			@include respond-to(small) {
				max-width: 450px;
			}
			@include respond-to(medium) {
				max-width: 750px;
			}
		}

		.category {
			margin: 10px 0;
			display: inline-block;
		}

		.location {
			color: $light-blue;
			float: right;

			@include respond-to(small) {
				position: absolute;
				top: 30px;
				right: 30px;
				margin: 0;
			}
		}
	}

	.action {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;

		@include respond-to(small) {
			align-items: flex-start;
			margin-top: 0;
		}

		.social-links {
			margin-top: -5px;

			a {
				margin-bottom: 0;
			}
		}

		.cta {
			margin: 0;
		}
	}
}
