.offices-advisors-map {
	padding: 0;
    @include respond-to(medium) {
        display: none;
    }
    
    .panel-toggle & {
        padding: 0;
    }
    .display-map + & {
        display: block;
    }
    .map-search-wrapper {
        background-color: $brand-color;
        padding: 15px 20px;
        text-align: center;
    }
    .map-search {
        position: relative;
        margin: 0 auto;
        @include respond-to(small) {
            width: 400px;
        }
        input[type=search] {
            appearance: none;
            border-radius: 0;
            border: 0 none;
            padding: 15px 50px 15px 15px;
            margin-bottom: 0;
            font-size: 20px;
            width: 100%;
            text-transform: capitalize;
            text-overflow: ellipsis;
        }
        input[type="submit"], button[type="submit"] {
            margin-top: 0;
        }
        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset;
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            height: 53px;
            background-color: transparent;
            padding: 5px 5px 0;
            .icon {
                width: 40px;
                height: 40px;
                fill: $link-color;
                $parameters: fill;
                @include autoThemeColor($parameters);
            }
        }
    }

	.map {
		width: 100%;
		height: 500px;
	}
}