// This module uses styles defined in components/_panel-toggle.scss
// The "contact" class is meant to be applied on the <body>
.contact {
  .panel-content {
    @include respond-to(medium) {
      margin-top: 40px;

      [data-sf-element="Contact details tab"] {
        float: none;
      }
    }

    section {
      padding: 0; // The form wrapper needs to be a <section> for SF, this workaround ensures no extra padding is added inside the panel-content
    }
  }

  // reduce padding if we show the map
  .panel-toggle.display-map {
      padding-bottom: 0;
      .panels-wrapper {
          padding-bottom: 0;
      }
  }

  .contact-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      color: $link-color;
      @include respond-to(medium) {
        color: $text-color;
      }
    }
    .icon {
      margin: 0px 10px 0 0;
    }
  }

  .card {
    flex-basis: 100%;
    @include respond-to(medium) {
      flex-basis: calc(50% - 30px);
      margin: 15px;
    }
    @include respond-to(large) {
      flex-basis: calc(33.3333% - 30px);
    }

    h3 {
      margin-top: 0;
      font-size: 30px;
      font-weight: lighter;
      color: $link-color;
    }
    .icon {
      margin: 20px 10px 0 0;

      @include respond-to(medium) {
        margin: 30px 10px 0 0;
      }
    }
  }

  .contact-card-wrapper-inner {
    display: flex;
  }

  .description {
    h4, h5, h6 {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .help-box {
    display: none;
  }
}
