.error-wrapper {
	min-height: calc(100vh - 128px - 261px); // Exclude the height of the header and the footer
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	color: #fff;

	.error-wrapper-inner {
		padding: 50px 0;

		@include respond-to(small) {
			width: 50%;
			padding: 60px 0px;
		}
		@include respond-to(medium) {
			width: 30%;
		}
	}

	.error-number {
		font-size: 100px;
		font-weight: 400;
		@include respond-to(small) {
			font-size: 200px;
			line-height: 1;
		}
	}

	.error-title {
		font-size: 28px;
		letter-spacing: 2px;
		margin-bottom: 30px;
	}

	.cta-rounded {
		margin: 30px 0;
		@include respond-to(small) {
			font-size: 24px;
			padding: 15px 30px;
			margin: 60px 0 20px;
		}
	}
}